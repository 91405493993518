import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import Done from "@mui/icons-material/Done";
import { RadioGroup } from "@mui/joy";
import { ThemeContext } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import { primaryColor } from "Routes";
import InfoIcon from "@mui/icons-material/Info";
import FadeInModal from "FadeInModal";
import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import Colorful from '@uiw/react-color-colorful';
import useWindowSize from "useWindowSize";
import simpleColorConverter from 'simple-color-converter';
var debounce = require("lodash.debounce");

export const ColorSelection = ({
  choices,
  currentMenu,
  setCurrentValue,
  currentValue,
  currentField,
  key,
}) => {
  const theme = useTheme();
  let { id } = useParams();
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [colorPickerColor, setColorPickerColor] = useState(null);
  const [colorPickerColorTitle, setColorPickerColorTitle] = useState(null);
  const [colorPickerModal, openColorPickerModal] = useState(false);

  const innerWidth = useWindowSize();
  // console.log("menu def: ", currentMenu)
  const debounced = debounce((value) => {
    setColorPickerColor(value);
    setColorPickerColorTitle(
      "RAL "+ new simpleColorConverter({
        hex: currentValue,
        to: 'ral'
      }).color.ral
      //window.ntc.name(value)[1]
      //converter.toHex(value)
    )
    setCurrentValue(value);
  }, 5);
  // useEffect(()=>{
   
  //   document.addEventListener('coloris:pick', event => {
  //     //console.log('New color', event.detail.color);
  //     debouceRequest(event.detail.color);
  //   });
  // },[])
  // useEffect(()=>{
  //   window.Coloris && window.Coloris({
  //     // themeMode: 'dark',
  //     alpha: false,
  //     inline:true,
  //     parent: '.picker-container',
  //   });
  //   console.log("GOT THIS PARAMETRIZAZION", currentMenu)
  // },[window.Coloris])
  useEffect(()=>{
    if(currentValue && currentValue[0] == "#" && choices.filter(o => o.rgb == currentValue).length == 0 && window.ntc){
      setColorPickerColor(currentValue);
      let color = new simpleColorConverter({
        hex: currentValue,
        to: 'ral'
      })
      console.log("FETCHED", color)
      setColorPickerColorTitle(
        //converter.toRal(currentValue)
        "RAL "+ new simpleColorConverter({
          hex: currentValue,
          to: 'ral'
        }).color.ral
        // window.ntc.name(currentValue)[1]
       
      )
    }
  },[currentValue])
  console.log("CURRENT COLOR: ",currentValue)
  const debouceRequest = useCallback((value) => debounced(value), []);
  // console.log("theme is:", theme);
  return (
    <>
    <Helmet>
    <script type="text/javascript" src="https://chir.ag/projects/ntc/ntc.js"></script>
    {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/mdbassit/Coloris@latest/dist/coloris.min.css"/>
<script src="https://cdn.jsdelivr.net/gh/mdbassit/Coloris@latest/dist/coloris.min.js"></script> */}
    </Helmet>
    <Box
      sx={{
        // resize: "horizontal",
        overflow: "auto",
        px: 0,
        height: innerWidth > 1200 ? "165px" : window.WURFL.is_mobile&& window.WURFL.form_factor === "Smartphone"?"135px":"120px",
        display: "flex",
        overflowY: "hidden",
        overflowX: innerWidth > 1200 ? "hidden": "auto",
        margin:
          innerWidth > 800
            ? "-35px auto 0px auto"
            : "-5px auto 6px auto",
        "::-webkit-scrollbar": window.WURFL.is_mobile&& window.WURFL.form_factor === "Smartphone" ?{ display: "none" }:null,
        //paddingLeft: choices ? (choices.length > 6 ? "20px" : "auto") : "auto",
     //padding: 0
      }}
    >
      {id && (
        <FadeInModal
          childrenHtml={infoModalText}
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
        />
      )}

      {currentField.addColorPicker && (
        <FadeInModal
        noBackdrop
        top={(window.innerHeight - 530) + "px"}
        children={
             <div className={"picker-container"}>
             <Colorful
        color={colorPickerColor}

        disableAlpha={true}
        onChange={(color) => {
         
          setColorPickerColor(color.hex);
          setColorPickerColorTitle(
            "RAL "+ new simpleColorConverter({
              hex: currentValue,
              to: 'ral'
            }).color.ral
          )
          setCurrentValue(color.hex)
          //debouceRequest(color.hex);
        }}
      /></div>
    }
        open={colorPickerModal}
        setOpen={openColorPickerModal}
      />
      )}

      {choices.length < 13 || innerWidth < 800 ? (
        <Box
          // aria-labelledby="product-color-attribute"
          // defaultValue="warning"
          sx={{
            gap: 2,
            // flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            height: innerWidth > 800 ? "auto" : "47px",
            margin: innerWidth > 800? "auto" : "20px auto",
            overflowX: innerWidth > 800 ? "visible" : "visible",
            // overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {(choices[0].numOrder != null
            ? choices
                .filter((i) => !i.hide)
                .sort((a, b) => a.numOrder - b.numOrder)
            : choices.filter((i) => !i.hide)
          ).map((color, key) => (
            <Box element={"span"} key={key} sx={{ height: "40px" }}>
              <Box
              title={(color.title && color.title) + " " + (color.numOrder&& "RAL " + color.numOrder)}
                element={"span"}
                className={"color-circle"}
                key={color.rgb}
                onClick={() => {
                  if (
                    currentValue != color.rgb ||
                    !currentMenu["menuConfiguration"].unselectOnClick
                  ) {
                    setCurrentValue(
                      color.material ? color.material : color.rgb
                    );
                  } else {
                    setCurrentValue(null);
                  }
                }}
                sx={{
                  display: "inline-block",
                  border:
                    color.rgb == currentValue || color.material == currentValue
                      ? "4px solid " +
                        (currentMenu.branding
                          ? currentMenu.branding.primaryColor.rgb
                          : "blue")
                      : "4px solid white",
                  borderColor:
                    color.rgb == currentValue || color.material == currentValue
                      ? currentMenu.branding
                        ? currentMenu.branding.primaryColor.rgb
                        : "blue"
                      : ( window.location.href.indexOf("embed") > -1
                      ? "rgb(250,250,250)"
                      : "white"),
                  // transition: "border .3s linear",
                  position: "relative",
                  width: 40,
                  height: 40,
                  flexShrink: 0,
                  // bgcolor: `${color}.solidBg`,
                  borderRadius: "50%",
                  display: "flex",
                  background: color.gradientRgb
                    ? "linear-gradient(-20deg, " +
                      color.rgb +
                      "," +
                      color.gradientRgb +
                      ")"
                    : color.rgb,
                  backgroundImage: color.backgroundImg
                    ? "url(' " + color.backgroundImg + " ')"
                    : null,
                  backgroundSize: "contain",
                  marginRight: color.title ? "25px" : "0",
                  marginLeft: color.title ? "29px" : "0",
                  marginBottom: color.title ? "30px" : "0",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:before":{borderColor: window.location.href.indexOf("embed") > -1
                  ? "rgb(250,250,250)"
                  : "white"},
                  "&:hover": {
                    borderColor:
                      color.rgb == currentValue ||
                      color.material == currentValue
                        ? currentMenu.branding
                          ? currentMenu.branding.primaryColor.rgb
                          : "blue"
                        : "lightgrey",
                  },
                  
                }}
              ></Box>
              {color.title && (
                <div
                  style={{
                    // marginBottom: "-80px",
                    marginTop: "-25px",
                    width: "100px",
                    textAlign: "center",
                    color: "#333",
                    fontSize: "0.85rem",
                    whiteSpace: "normal",
                    lineHeight: "0.8rem",
                  }}
                >
                  <span
                    style={{
                      fontSize: color.title.length > 14 ? "0.75rem" : "inherit",
                    }}
                  >
                    {color.title}
                  </span>{" "}
                  
                  {(color.price || color.price == 0) && (
                    <small style={{ display: "block", color: "#666" }}>
                      {!color.exact && "ab "}
                      <CurrencyFormat
                        suffix={" €"}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        value={color.price}
                      />
                    </small>
                  )}
                </div>
              )}
            </Box>
          ))}
 {
            currentField.addColorPicker && (
              <label style={{background: "transparent"}} onClick={e => {openColorPickerModal(true); colorPickerColor && setCurrentValue(colorPickerColor)}}class="color-selector" >
              <Box element={"span"} key={key} sx={{ height: "40px", background: "transparent" }}>
                
                  <span
                    class="circle"
                    style={{
                      display: "inline-block",
                      border:
                       currentValue ==
                        colorPickerColor
                          ? "4px solid " +
                            (currentMenu.branding
                              ? currentMenu.branding.primaryColor.rgb
                              : "blue")
                          : "4px solid white",
                      borderColor:
                        currentValue ==
                       colorPickerColor
                          ? currentMenu.branding
                            ? currentMenu.branding.primaryColor.rgb
                            : "blue"
                          : "white",
                      // transition: "border .3s linear",
                      position: "relative",
                      width: 40,
                      height: 40,
                      flexShrink: 0,
                      // bgcolor: `${color}.solidBg`,
                      borderRadius: "50%",
                      display: "flex",
                      background: 
                      // colorPickerColor
                      //   ? colorPickerColor
                      //   : 
                        `linear-gradient(
                      45deg,
                      rgba(255, 0, 0, 1) 0%,
                      rgba(255, 154, 0, 1) 10%,
                      rgba(208, 222, 33, 1) 20%,
                      rgba(79, 220, 74, 1) 30%,
                      rgba(63, 218, 216, 1) 40%,
                      rgba(47, 201, 226, 1) 50%,
                      rgba(28, 127, 238, 1) 60%,
                      rgba(95, 21, 242, 1) 70%,
                      rgba(186, 12, 248, 1) 80%,
                      rgba(251, 7, 217, 1) 90%,
                      rgba(255, 0, 0, 1) 100%
                  )`,

                      marginRight: innerWidth > 800 ? "25px" : "0",
                      marginLeft: innerWidth > 800 ? "25px" : "0",
                      marginBottom: innerWidth > 800 ? "30px" : "0",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    data-reactid=".0.2.0"
                  >
                   
                      <span
                        style={{
                          marginBottom: "-80px",
                          width: "150px",
                          textAlign: "center",
                          color: "#333",
                          fontSize: "0.85rem",
                        }}
                      >
                        {<span style={{marginTop: "-10px", lineHeight: "0.8rem", fontSize: colorPickerColorTitle && colorPickerColorTitle.length > 14 ? "0.75rem" : "inherit",}} data-reactid=".0.2.1">
                          {colorPickerColorTitle
                            ? colorPickerColorTitle
                            : "Custom"}
                        </span>}
                      </span>
                    
                  </span>
                 

              
              
              </Box>
              </label>
            )

            // <input
            //   onChange={(e) => {
            //     debouceRequest(e.target.value);
            //     // setCurrentValue(e.target.value);
            //   }}
            //   type={"color"}
            // ></input>
          }
       

          {currentMenu["menuConfiguration"].addNullOption && (
            <>
              <Box
                element={"span"}
                className={"color-circle none-option"}
                key={"none"}
                onClick={() => setCurrentValue(null)}
                sx={{
                  "&:before":{borderColor: window.location.href.indexOf("embed") > -1
                  ? "rgb(250,250,250)"
                  : "white"},
                  display: "inline-block",
                  border:
                    null == currentMenu["menuConfiguration"].currentValue
                      ? "4px solid blue"
                      : "4px solid white",
                  position: "relative",
                  width: 40,
                  height: 40,
                  flexShrink: 0,
                  // bgcolor: `${color}.solidBg`,
                  borderRadius: "50%",
                  // display: 'flex',
                  // flexDirection: "row",
                  background: "#fff",
                  marginRight:
                    currentMenu["menuConfiguration"].choices[0].title &&
                    innerWidth > 800
                      ? "25px"
                      : "0",
                  marginLeft:
                    currentMenu["menuConfiguration"].choices[0].title &&
                    innerWidth > 800
                      ? "25px"
                      : "0",
                  marginBottom:
                    currentMenu["menuConfiguration"].choices[0].title &&
                    innerWidth > 800
                      ? "30px"
                      : "0",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {currentMenu["menuConfiguration"].choices[0].title &&
                  innerWidth > 800 && (
                    <div style={{ marginBottom: "-70px", width: "100px" }}>
                      Keine
                    </div>
                  )}
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Slider
        
          style={{
            width:
              choices.length <= 10
                ? (choices.length + (currentField.addColorPicker? 1 : 0)) * 100 + "px"
                : "calc(100vw)",
            margin: "auto",
           
          }}
          {...{
            infinite: true,
            //draggable: false,
            // className: "control-slider",
            swipe: true,
            speed: 850,
            focusOnSelect: choices.length <= 10 ? false : true,
            centerMode: choices.length <= 10 ? false : true,
            touchThreshold: 11,
            // has no effect usually:
            initialSlide: 17,
            slidesToShow:
              choices.length <= 15
                ? choices.length - 1
                : innerWidth > 800
                ? 15
                : 2.5,
            slidesToScroll: 1,
            arrows: choices.length <= 10 ? false : false, //true,
            
          }}
        >
           {
            currentField.addColorPicker && (
              <label style={{background: "transparent"}} onClick={e => {openColorPickerModal(true); colorPickerColor && setCurrentValue(colorPickerColor)}}class="color-selector" >
              <Box element={"span"} key={key} sx={{ height: "40px", background:"transparent" }}>
                
                  <span
                    class="circle"
                    style={{
                      display: "inline-block",
                      border:
                       currentValue ==
                        colorPickerColor
                          ? "4px solid " +
                            (currentMenu.branding
                              ? currentMenu.branding.primaryColor.rgb
                              : "blue")
                          : "4px solid white",
                      borderColor:
                        currentValue ==
                       colorPickerColor
                          ? currentMenu.branding
                            ? currentMenu.branding.primaryColor.rgb
                            : "blue"
                          : "white",
                      // transition: "border .3s linear",
                      position: "relative",
                      width: 40,
                      height: 40,
                      flexShrink: 0,
                      // bgcolor: `${color}.solidBg`,
                      borderRadius: "50%",
                      display: "flex",
                      background: 
                      // colorPickerColor
                      //   ? colorPickerColor
                      //   : 
                        `linear-gradient(
                      45deg,
                      rgba(255, 0, 0, 1) 0%,
                      rgba(255, 154, 0, 1) 10%,
                      rgba(208, 222, 33, 1) 20%,
                      rgba(79, 220, 74, 1) 30%,
                      rgba(63, 218, 216, 1) 40%,
                      rgba(47, 201, 226, 1) 50%,
                      rgba(28, 127, 238, 1) 60%,
                      rgba(95, 21, 242, 1) 70%,
                      rgba(186, 12, 248, 1) 80%,
                      rgba(251, 7, 217, 1) 90%,
                      rgba(255, 0, 0, 1) 100%
                  )`,

                      marginRight: innerWidth > 800 ? "25px" : "0",
                      marginLeft: innerWidth > 800 ? "25px" : "0",
                      marginBottom: innerWidth > 800 ? "30px" : "0",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    data-reactid=".0.2.0"
                  >
                    {innerWidth > 800 && (
                      <span
                        style={{
                          marginBottom: "-80px",
                          width: "150px",
                          textAlign: "center",
                          color: "#333",
                          fontSize: "0.85rem",
                        }}
                      >
                        {colorPickerColor == currentValue &&<span style={{marginTop: "-10px", lineHeight: "0.8rem", fontSize: colorPickerColorTitle && colorPickerColorTitle.length > 14 ? "0.75rem" : "inherit",}} data-reactid=".0.2.1">
                          {colorPickerColor
                            ? colorPickerColorTitle
                            : ""}
                        </span>}
                      </span>
                    )}
                  </span>
                 

              
              
              </Box>
              </label>
            )

            // <input
            //   onChange={(e) => {
            //     debouceRequest(e.target.value);
            //     // setCurrentValue(e.target.value);
            //   }}
            //   type={"color"}
            // ></input>
          }
          {(choices[0].numOrder != null
            ? choices
                .filter((i) => !i.hide)
                .sort((a, b) => a.numOrder - b.numOrder)
            : choices.filter((i) => !i.hide)
          ).map((color, key) => (
            <Box element={"span"} key={key} sx={{ height: "40px" }}>
              <Box
                title={(color.title && color.title + " ") + (color.numOrder && (("" + color.numOrder).length == 4 ? "RAL " : "") + color.numOrder)}
                element={"span"}
                className={"color-circle"}
                key={color.rgb}
                onClick={() => {
                  if (
                    currentValue != color.rgb ||
                    !currentMenu["menuConfiguration"].unselectOnClick
                  ) {
                    setCurrentValue(
                      color.material ? color.material : color.rgb
                    );
                  } else {
                    setCurrentValue(null);
                  }
                }}
                sx={{
                  display: "inline-block",
                  border:
                    color.rgb == currentValue || color.material == currentValue
                      ? "4px solid " +
                        (currentMenu.branding
                          ? currentMenu.branding.primaryColor.rgb
                          : "blue")
                      : "4px solid white",
                  borderColor:
                    (color.rgb == currentValue || color.material == currentValue)
                      ? currentMenu.branding
                        ? currentMenu.branding.primaryColor.rgb
                        : "blue"
                      : (window.location.href.indexOf("embed") > -1
                      ? "rgb(250,250,250)"
                      : "white"),
                  // transition: "border .3s linear",
                  position: "relative",
                  width: 40,
                  height: 40,
                  flexShrink: 0,
                  // bgcolor: `${color}.solidBg`,
                  borderRadius: "50%",
                  display: "flex",
                  background: color.gradientRgb
                    ? "linear-gradient(-20deg, " +
                      color.rgb +
                      "," +
                      color.gradientRgb +
                      ")"
                    : color.rgb,
                  backgroundImage: color.backgroundImg
                    ? "url(' " + color.backgroundImg + " ')"
                    : null,
                  backgroundSize: "contain",
                  marginRight: color.title ? "25px" : "0",
                  marginLeft: color.title ? "29px" : "0",
                  marginBottom: color.title ? "30px" : "0",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:before":{borderColor: window.location.href.indexOf("embed") > -1
                  ? "rgb(250,250,250)"
                  : "white"},
                  "&:hover": {
                    borderColor:
                      (color.rgb == currentValue ||
                      color.material == currentValue)
                        ? currentMenu.branding
                          ? currentMenu.branding.primaryColor.rgb
                          : "blue"
                        : "lightgrey",
                  },
                }}
              ></Box>
              {color.title && (choices.length < 10 || color.rgb == currentValue) && (
                <div
                className={"animate__animated animate__fadeIn"}
                  style={{
                    // marginBottom: "-80px",
                    marginTop: "-25px",
                    width: "100px",
                    textAlign: "center",
                    color: "#333",
                    fontSize: "0.85rem",
                    whiteSpace: "normal",
                    lineHeight: "0.8rem",
                  }}
                >
                  <span
                    style={{
                      fontSize: color.title.length > 14 ? "0.75rem" : "inherit",
                      color: "#333"
                    }}
                  >
                    {color.title}
                  </span>{" "}
                 
                  {(color.price || color.price == 0) && (
                    <small style={{ display: "block", color: "#666" }}>
                      <CurrencyFormat
                        suffix={" €"}
                        displayType={"text"}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        value={color.price}
                      />
                    </small>
                  )}
                </div>
              )}
            </Box>
            
          ))}
       
        </Slider>
      )}
    </Box>
    </>
  );
};
