import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Grid, Modal } from "@mui/material";
import { PrimaryColor, primaryColor } from "Routes";
import InfoIcon from "@mui/icons-material/Info";
import FadeInModal from "FadeInModal";
import { useState } from "react";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Apps from "@mui/icons-material/Apps";
import { PropaneSharp } from "@mui/icons-material";
import CurrencyFormat from "react-currency-format";
import { ModalDialog, Tooltip } from "@mui/joy";
import { Transition } from "react-transition-group";
import CloseIcon from "@mui/icons-material/Close";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { DistanceMatrixService, useJsApiLoader } from "@react-google-maps/api";
import CountUp from "react-countup";
import { doc, setDoc } from "firebase/firestore";
import { db } from "index";
import { useParams } from "react-router-dom";
import useWindowSize from "useWindowSize";

export function AppsMenu() {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const innerWidth = useWindowSize();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <IconButton
        ref={buttonRef}
        id="apps-menu-demo"
        aria-label="Applications"
        aria-controls={"apps-menu"}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="plain"
        color="neutral"
        onClick={() => {
          setOpen(!open);
        }}
        sx={{ borderRadius: 40 }}
      >
        <Apps />
      </IconButton>
      <Menu
        id="apps-menu"
        variant="solid"
        invertedColors
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        aria-labelledby="apps-menu-demo"
        sx={{
          "--List-padding": "0.5rem",
          "--ListItemDecorator-size": "3rem",
          display: "grid",
          gridTemplateColumns: "repeat(3, 100px)",
          gridAutoRows: "100px",
          gap: 1,
        }}
      >
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>S</Avatar>
          </ListItemDecorator>
          Search
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Maps
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>M</Avatar>
          </ListItemDecorator>
          Mail
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>D</Avatar>
          </ListItemDecorator>
          Drive
        </MenuItem>
        <MenuItem orientation="vertical" onClick={handleClose}>
          <ListItemDecorator>
            <Avatar>C</Avatar>
          </ListItemDecorator>
          Calendar
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function ConfiguratorSelectField({
  options,
  setCurrentValue,
  currentValue,
  largeMenu,
  field,
  currentMenu,
  submitted,
  price,
  configuration,
  setConfiguration,
  setDeliveryCosts,
  deliveryDestination,
  setDeliveryDestination,
}) {
  const [infoModalText, setInfoModalText] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [deliveryModalOpen, setDeliverModalOpen] = useState(false);
  const [adress, setAdress] = useState(null);
  const [distance, setDistance] = useState(0);
  const [destination, setDestination] = useState(
    deliveryDestination
      ? deliveryDestination
      : { lat: 50.1392953, lng: 8.144082 }
  );
  const innerWidth = useWindowSize();

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyBTmxMWFGNRQ5QtJ2XnPBlFxxSl368w6Gg"
  // })

  React.useEffect(() => {
    setDestination(deliveryDestination);
    if (deliveryDestination) {
      try {
        let geocoder = new window.google.maps.Geocoder();
        let adress;
        geocoder.geocode(
          { location: deliveryDestination },
          function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                console.log("ADRESS", results)
                setAdress(results[1].formatted_address)
              }
            }
          }
        )  
      } catch (e) {
        console.log(e, "Error retrieving adress");
      }
    }
  }, [deliveryDestination]);

  React.useEffect(() => {
    let v = field.options.filter((i) => i.title == currentValue)[0];
    console.log("optsss", field.options, v);
    if (v && v.deliveryModal) {
      setDeliveryCosts(distance.toFixed(0) * 2);
    } else {
      setDeliveryCosts(0);
      setCurrentValue(currentValue);
    }
  }, [distance, currentValue]);

  React.useEffect(() => {
    console.log("adresse ist", adress, configuration);
  }, [adress]);

  let { configurationId } = useParams();
  let pc = PrimaryColor();
  const isValid = (value) => {
    return true;
  };
  return (
    <>
      {field.options.filter((i) => i.deliveryModal).length > 0 && (
        <Transition in={deliveryModalOpen} timeout={400}>
          {(state) => (
            <Modal
              keepMounted
              open={!["exited", "exiting"].includes(state)}
              onClose={() => {
                setDeliverModalOpen(false);
                // trigger price recalculation:
                setCurrentValue(currentValue);
              }}
              slotProps={{
                backdrop: {
                  sx: {
                    opacity: 0,
                    backdropFilter: "none",
                    transition: `opacity 400ms, backdrop-filter 400ms`,
                    ...{
                      entering: { opacity: 1, backdropFilter: "blur(8px)" },
                      entered: { opacity: 1, backdropFilter: "blur(8px)" },
                    }[state],
                  },
                },
              }}
              sx={{
                visibility: state === "exited" ? "hidden" : "visible",
                height: "auto",
                maxHeight: "auto",
                overflow: "scroll",
              }}
            >
              <ModalDialog
                aria-labelledby="fade-modal-dialog-title"
                aria-describedby="fade-modal-dialog-description"
                sx={{
                  height: "auto",
                  maxHeight: "500vh",
                  //marginY: innerWidth > 800 ? null : "150px",
                  paddingBottom: "70px",

                  opacity: 0,
                  transition: `opacity 300ms height`,
                  ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                  }[state],
                }}
              >
                <CloseIcon
                  sx={{ float: "right", ml: "auto", cursor: "Pointer" }}
                  onClick={() => setDeliverModalOpen(false)}
                />
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#25252d",
                  }}
                >
                  Lieferkosten kalkulieren
                </h2>
                <h5>Lieferadresse</h5>
                <GooglePlacesAutocomplete
                  // autocompletionRequest={{ types: ["administrative_area_level_1"] }}
                  onChange={(e) => console.log("Adress selected: ", e)}
                  selectProps={{
                    value: adress ? { value: adress, label: adress } : false,
                    onChange: (e) => {
                      if (e == null) {
                        setAdress("");
                      } else {
                        console.log(e);
                        geocodeByPlaceId(e.value.place_id)
                          .then((results) => {
                            console.log(
                              "AUTOCOMPLETE RESULT",
                              results[0].geometry.location.lat()
                            );
                            try {
                              setDestination({
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                              });
                              setDeliveryDestination({
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                              });
                            } catch (e) {
                              console.log(e);
                            }
                            console.error(results);
                            setAdress(results[0].formatted_address);
                          })
                          .catch((error) => console.error(error));
                      }
                    },
                    placeholder: "Musterweg 15, 12345 Musterstadt",
                    isClearable: true,
                    backspaceRemovesValue: true,
                    styles: {
                      placeholder: (provided, state) => ({
                        ...provided,
                        display: state.isFocused ? "none" : undefined,
                      }),
                    },
                  }}
                  placeholder={"Adresse"}
                  //  apiKey={"AIzaSyBTmxMWFGNRQ5QtJ2XnPBlFxxSl368w6Gg"}
                />
                <DistanceMatrixService
                  options={{
                    destinations: [destination],
                    origins: [{ lat: 50.1392953, lng: 8.144082 }],
                    travelMode: "DRIVING",
                  }}
                  callback={(res) => {
                    console.log("RESPONSE", res);
                    try {
                      setDistance(
                        res.rows[0].elements[0].distance.value / 1000
                      );
                    } catch (e) {
                      console.log(e);
                    }
                    // try{
                    //   setConfiguration(
                    //     [...configuration, {title: "Lieferkosten", value: res.rows[0].elements[0].distance.value / 1000*2 } ]

                    //   );
                    // }catch(e){
                    //   console.log(e)
                    // }
                    // this.setState({
                    //   totalTime: res.rows[0].elements[0].duration.text,
                    //   totalDistance: res.rows[0].elements[0].distance.text,
                    // });
                  }}
                />
                <h5 style={{ marginBottom: 0, paddingBottom: 0 }}>
                  Entfernung zum Klangschild Headquater
                </h5>
                <small>(Stephanstr. 53, 65232 Taunusstein)</small>
                <br />
                <div>{distance.toFixed(0)} km</div>
                <h5>Lieferkosten</h5>
                <div style={{ color: "black" }}>
                  <CountUp
                    duration={1}
                    end={distance.toFixed(0) * 2}
                    start={0}
                    suffix={" €"}
                  />
                </div>
                <h5>Pauschale</h5>
                {
                  field.options.filter((i) => i.title == currentValue)[0].price
                }{" "}
                €
                {/* <h5>Gesamtkosten</h5>
                {(price?price:0)} € */}
              </ModalDialog>
            </Modal>
          )}
        </Transition>
      )}
      <Box
        className={"select-options-container"}
        sx={{
          paddingLeft:
            options.length > 2 && innerWidth < 800
              ? "calc(50vw - 157px)"
              : "0px",
          height:
            field.inputType == "layeredSelect"
              ? "55px"
              : window.WURFL.is_mobile &&
                window.WURFL.form_factor === "Smartphone"
              ? "130px"
              : "105px",
          // display: "flex",
          // margin: "auto",
          flexDirection: "horizontal",
          textAlign: "center",
          overflowX: innerWidth > 800 ? "auto" : "auto",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          //scrollbarWidth: "none",
          paddingTop: "4px",
          "::-webkit-scrollbar":
            window.WURFL.is_mobile && window.WURFL.form_factor === "Smartphone"
              ? { display: "none" }
              : null,

          marginTop:
            field.inputType == "layeredSelect" && innerWidth < 800
              ? "-3px"
              : null,
          marginBottom:
            field.inputType == "layeredSelect" && !field.colors
              ? "5px"
              : field.inputType == "layeredSelect"
              ? "7px"
              : 0,
          // width: (innerWidth > 800 ? "auto" : "250px")
        }}
      >
        <FadeInModal
          childrenHtml={infoModalText}
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
        />
        <Box
          sx={{
            margin: "auto",
            overflow: "hidden",
            display: innerWidth < 800 && largeMenu ? "inline" : "inline",
          }}
        >
          {/* <AppsMenu/> */}
          <Box
            sx={{
              display: innerWidth > 800 ? "inline-block" : "inline-block",
            }}
          >
            <div
              style={{
                height: "0px",
                width: "100%",
                marginBottom: "-8px",
                marginLeft: "-8px",
                textAlign: "right",
                zIndex: 100,
              }}
            ></div>
          </Box>

          {options.map((value) => (
            <Box
              sx={{
                display: innerWidth > 800 ? "inline-block" : "inline-block",
              }}
            >
              <div
                style={{
                  height: "0px",
                  width: "100%",
                  marginBottom: field.colors ? "-8px" : "0px",
                  marginTop: field.colors ? "0px" : "-10px",
                  marginLeft: "-8px",
                  textAlign: "right",
                  zIndex: 100,
                }}
              >
                {currentValue == value.title &&
                  field.inputType == "singleSelect" && (
                    <span
                      style={{
                        background: "transparent",
                        padding: "3px",
                        minWidth: "27px",
                        marginTop: "6px",
                        display: "inline-block",
                      }}
                    >
                      <svg
                        width={"20px"}
                        fill={pc && pc.primaryColor}
                        stroke="white"
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="CheckCircleRoundedIcon"
                      >
                        <path
                          fill={pc && pc.primaryColor}
                          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
                        ></path>
                      </svg>
                    </span>
                  )}
              </div>
              <Tooltip
                sx={{
                  marginLeft: "-25px",
                  left: "-20px",
                  fontSize: "12px",
                  background: "rgba(80,80,80,0.92)",
                  display: innerWidth > 800 ? "auto" : "none",
                }}
                title={
                  currentValue &&
                  currentValue[2] &&
                  field.inputType == "layeredSelect" &&
                  value.prohibitedCombinations?.includes(currentValue[2]) ? (
                    "EasyCUBE nur ohne Verkleidung"
                  ) : value.prohibitedCombinations &&
                    value.prohibitedCombinations.values &&
                    value.prohibitedCombinations.values.length > 0 &&
                    configuration.filter((obj) =>
                      value.prohibitedCombinations.values.includes(
                        obj.currentValue
                      )
                    ).length > 0 ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: value.prohibitedCombinations.message,
                      }}
                    ></span>
                  ) : (
                    ""
                  )
                }
                placement={field.inputType == "layeredSelect" ? "right" : "top"}
              >
                <Box
                  variant="outlined"
                  onClick={() => {
                    if (
                      !(
                        (currentValue &&
                          currentValue[2] &&
                          field.inputType == "layeredSelect" &&
                          value.prohibitedCombinations?.includes(
                            currentValue[2]
                          )) ||
                        (value.prohibitedCombinations &&
                          value.prohibitedCombinations.values &&
                          value.prohibitedCombinations.values.length > 0 &&
                          configuration.filter((obj) =>
                            value.prohibitedCombinations.values.includes(
                              obj.currentValue
                            )
                          ).length > 0)
                      )
                    )
                      if (
                        field.inputType == "singleSelect" &&
                        isValid(value.title)
                      ) {
                        if (value.deliveryModal) {
                          setDeliverModalOpen(true);
                        }
                        if (currentValue != value.title) {
                          setCurrentValue(value.title);
                        } else {
                          setCurrentValue(null);
                        }
                      }
                    if (field.inputType == "layeredSelect") {
                      if (
                        currentValue &&
                        currentValue[2] &&
                        value.prohibitedCombinations?.includes(currentValue[2])
                      ) {
                        console.log("prohibited combination!");
                      } else if (
                        currentValue &&
                        [currentValue[0], currentValue[1]] !=
                          [value.title, value.subtitle]
                      ) {
                        setCurrentValue([
                          value.title,
                          value.subtitle ? value.subtitle : "",
                          currentValue[2],
                        ]);
                        console.log("SETTING STATE: ", [
                          value.title,
                          value.subtitle,
                          currentValue[2],
                        ]);
                      } else {
                        setCurrentValue([
                          null,
                          null,
                          currentValue && currentValue[2]
                            ? currentValue[2]
                            : null,
                        ]);
                      }
                    }
                  }}
                  sx={{
                    border:
                      currentValue != null &&
                      (currentValue == value.title ||
                        !field.inputType == "layeredSelect" ||
                        currentValue[0] == value.title)
                        ? // &&currentValue[1] == value.subtitle
                          (currentMenu.branding?.selectBorderWidth
                            ? currentMenu.branding.selectBorderWidth
                            : "2px") +
                          " solid " +
                          (pc && pc.primaryColor)
                        : (currentMenu.branding?.selectBorderWidth
                            ? currentMenu.branding.selectBorderWidth
                            : "2px") +
                          " solid " +
                          (currentMenu.branding?.inactiveBorderColor
                            ? currentMenu.branding.inactiveBorderColor
                            : "8px"),

                    borderRadius:
                      field.inputType == "singleSelect"
                        ? currentMenu.branding?.borderRadius
                          ? currentMenu.branding.borderRadius
                          : "8px"
                        : "40px",
                    bgcolor: "background.body",
                    // border: "0.3px solid #ccc",
                    boxShadow: currentMenu.branding?.boxShadow
                      ? currentMenu.branding.boxShadow
                      : `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                    background:
                      (currentValue &&
                        currentValue[2] &&
                        field.inputType == "layeredSelect" &&
                        value.prohibitedCombinations?.includes(
                          currentValue[2]
                        )) ||
                      (value.prohibitedCombinations &&
                        value.prohibitedCombinations.values &&
                        value.prohibitedCombinations.values.length > 0 &&
                        configuration.filter((obj) =>
                          value.prohibitedCombinations.values.includes(
                            obj.currentValue
                          )
                        ).length > 0)
                        ? "rgb(246,246,246)"
                        : "white",
                    pointerEvents: submitted ? "none" : "auto",
                    cursor:
                      (currentValue &&
                        currentValue[2] &&
                        field.inputType == "layeredSelect" &&
                        value.prohibitedCombinations?.includes(
                          currentValue[2]
                        )) ||
                      (value.prohibitedCombinations &&
                        value.prohibitedCombinations.values &&
                        value.prohibitedCombinations.values.length > 0 &&
                        configuration.filter((obj) =>
                          value.prohibitedCombinations.values.includes(
                            obj.currentValue
                          )
                        ).length > 0)
                        ? "not-allowed"
                        : "pointer",
                    color:
                      (currentValue &&
                        currentValue[2] &&
                        field.inputType == "layeredSelect" &&
                        value.prohibitedCombinations?.includes(
                          currentValue[2]
                        )) ||
                      (value.prohibitedCombinations &&
                        value.prohibitedCombinations.values &&
                        value.prohibitedCombinations.values.length > 0 &&
                        configuration.filter((obj) =>
                          value.prohibitedCombinations.values.includes(
                            obj.currentValue
                          )
                        ).length > 0)
                        ? "#888"
                        : "black",
                    marginTop: "-30px",
                    // transition: "border-color .3s linear",
                    padding:
                      field.inputType == "layeredSelect"
                        ? !(field.options[0].price >= 0)
                          ? "5px 16px"
                          : "2px 16px"
                        : "4px",

                    //   display: "inline-block",
                    minWidth: 100,

                    height: "auto",
                    maxHeight: "150px",
                    display: !value.image ? "flex" : "auto",

                    m: 1,
                    marginTop:
                      currentValue &&
                      currentValue[0] == value.title &&
                      currentValue[1] == value.subtitle
                        ? "8px"
                        : "9px",
                    marginLeft:
                      currentValue &&
                      currentValue[0] == value.title &&
                      currentValue[1] == value.subtitle
                        ? "8px"
                        : "9px",
                    marginRight:
                      currentValue &&
                      currentValue[0] == value.title &&
                      currentValue[1] == value.subtitle
                        ? "8px"
                        : "9px",
                  }}
                >
                  {value.image && (
                    <img
                      src={value.image}
                      width={"120px"}
                      height={innerWidth < 1200 ? "40px" : "45px"}
                      style={{ objectFit: "contain", margin: "auto" }}
                    />
                  )}
                  <Box
                    sx={{
                      margin: "auto",
                      display: "inline",
                      fontSize: "0.85rem",
                    }}
                  >
                    <span
                      style={{ lineHeight: "9px" }}
                      dangerouslySetInnerHTML={{
                        __html: value.verboseTitle
                          ? value.verboseTitle
                          : value.title,
                      }}
                    ></span>
                    {/* {value.info && (
                    <InfoIcon
                      sx={{
                        display: "inline",
                        "& :hover": { color: "black" },
                        color: "grey",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        marginLeft: "2px",
                        fontSize: "0.9rem",
                      }}
                      onClick={() => {
                        setInfoModalText(value.info);
                        setInfoModalOpen(true);
                      }}
                    />
                  )} */}{" "}
                    {options.filter((opt) => opt.price).length > 0 && (
                      <>
                        <small
                          style={{
                            color: "grey",
                            whiteSpace: "pre-wrap",
                            display: "block",
                            marginTop: "-4px",
                          }}
                        >
                          {value.price || value.price == 0
                            ? (!value.exactPrice ? "ab " : "") +
                              (value.price +
                                (value.deliveryModal
                                  ? distance.toFixed(0) * 2
                                  : 0)) +
                              " €"
                            : " "}
                        </small>
                      </>
                    )}
                    {options.filter((opt) => opt.subtitle).length > 0 && (
                      <>
                        <small
                          style={{
                            color: "grey",
                            whiteSpace: "pre-wrap",
                            display: "block",
                            marginTop: "-4px",
                          }}
                        >
                          {value.subtitle ? value.subtitle : " "}
                        </small>
                      </>
                    )}
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Box>

      {field.inputType == "layeredSelect" && field.subchoices && (
        <Box
          className={"select-options-container"}
          sx={{
            paddingLeft:
              options.length > 2 && innerWidth < 800
                ? "calc(50vw - 87px)"
                : "0px",
            height: innerWidth > 800 ? "105px" : "105px",
            // display: "flex",
            // margin: "auto",
            flexDirection: "horizontal",
            textAlign: "center",
            overflowX: innerWidth > 800 ? "scroll" : "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollbarWidth: "none",
            marginTop: field.colors ? "-30px" : "-15px",

            // width: (innerWidth > 800 ? "auto" : "250px")
          }}
        >
          <FadeInModal
            childrenHtml={infoModalText}
            open={infoModalOpen}
            setOpen={setInfoModalOpen}
          />

          <Box
            sx={{
              margin: "auto",
              overflow: "hidden",
              display: innerWidth < 800 && largeMenu ? "inline" : "inline",
              marginTop: field.colors ? "-10px" : "4px",
              paddingTop: "10px",
            }}
          >
            {!field.colors &&
              field.subchoices.map((value) => {
                return (
                  <Box sx={{ display: "inline-block" }}>
                    <div
                      style={{
                        height: "0px",
                        width: "100%",
                        marginBottom: "-5px",
                        marginLeft: "-3px",
                        textAlign: "right",
                        zIndex: 100,
                      }}
                    >
                      {currentValue && currentValue[2] == value.title && (
                        <span
                          style={{
                            background: "transparent",
                            minWidth: "24px",
                            display: "inline-block",
                            marginTop: "5px",
                            marginRight: "6px",
                          }}
                        >
                          <svg
                            width={"20px"}
                            fill={pc && pc.primaryColor}
                            stroke="white"
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="CheckCircleRoundedIcon"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path>
                          </svg>
                        </span>
                      )}
                    </div>

                    <Box
                      variant="outlined"
                      onClick={() => {
                        if (
                          currentValue[2] != value.title &&
                          !field.options
                            .filter((i) => i.title == currentValue[0])
                            .filter((l) => l.subtitle == currentValue[1])[0]
                            .prohibitedCombinations?.includes(value.title)
                        ) {
                          setCurrentValue([
                            currentValue[0],
                            currentValue[1],
                            value.title,
                          ]);
                        } else {
                          setCurrentValue([
                            currentValue[0],
                            currentValue[1],
                            null,
                          ]);
                        }
                      }}
                      sx={{
                        border:
                          currentValue && currentValue[2] == value.title
                            ? "2px solid " + (pc && pc.primaryColor)
                            : "2px solid white",

                        borderRadius: "8px",
                        bgcolor: "background.body",
                        // border: "0.3px solid #ccc",
                        boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 8px`,
                        // marginTop: "-30px",

                        cursor: "pointer",
                        padding: "5px",
                        filter:
                          currentValue &&
                          field.options
                            .filter((i) => i.title == currentValue[0])
                            .filter((l) => l.subtitle == currentValue[1])[0]
                            ?.prohibitedCombinations?.includes(value.title)
                            ? "grayscale(0.5)"
                            : "none",
                        cursor:
                          currentValue &&
                          field.options
                            .filter((i) => i.title == currentValue[0])
                            .filter((l) => l.subtitle == currentValue[1])[0]
                            ?.prohibitedCombinations?.includes(value.title)
                            ? "not-allowed"
                            : "pointer",
                        //   display: "inline-block",
                        minWidth: 100,
                        background:
                          currentValue &&
                          field.options
                            .filter((i) => i.title == currentValue[0])
                            .filter((l) => l.subtitle == currentValue[1])[0]
                            ?.prohibitedCombinations?.includes(value.title)
                            ? "rgb(246,246,246)"
                            : "white",
                        height: "auto",
                        minHeight: "65px",
                        maxHeight: "150px",
                        display: !value.image ? "flex" : "auto",
                        m: 1,
                        marginTop:
                          currentValue && currentValue[2] == value.title
                            ? "8px"
                            : "9px",
                        marginLeft:
                          currentValue && currentValue[2] == value.title
                            ? "8px"
                            : "9px",
                        marginRight:
                          currentValue && currentValue[2] == value.title
                            ? "8px"
                            : "9px",
                      }}
                    >
                      <Grid container sx={{ margin: "auto" }}>
                        <Grid xs={4} sx={{ margin: "auto" }}>
                          {value.image && (
                            <img
                              src={value.image}
                              width={"60px"}
                              height={innerWidth < 800 ? "45px" : "45px"}
                              style={{
                                objectFit: "contain",
                                marginRight: "14px",
                              }}
                            />
                          )}
                        </Grid>
                        <Grid sx={{ margin: "auto" }} xs={8}>
                          <Box
                            sx={{
                              whiteSpace: "normal",
                              lineHeight: "0.8rem",
                              margin: "auto",
                              display: "inline",
                              fontSize: "0.85rem",
                            }}
                          >
                            {value.verboseTitle
                              ? value.verboseTitle
                              : value.title}{" "}
                            {/* {value.info && (
                          <InfoIcon
                            sx={{
                              display: "inline",
                              "& :hover": { color: "black" },
                              color: "grey",
                              cursor: "pointer",
                              marginBottom: "-3px",
                              marginLeft: "2px",
                              fontSize: "0.9rem",
                            }}
                            onClick={() => {
                              setInfoModalText(value.info);
                              setInfoModalOpen(true);
                            }}
                          />
                        )} */}{" "}
                            {options.filter((opt) => opt.price).length > 0 && (
                              <>
                                <small
                                  style={{
                                    color: "grey",
                                    whiteSpace: "pre-wrap",
                                    display: "block",
                                    marginTop: "-4px",
                                  }}
                                >
                                  {value.price
                                    ? "+ " + value.price + " €"
                                    : " "}
                                </small>
                              </>
                            )}
                            {field.subchoices.filter((opt) => opt.subtitle)
                              .length > 0 && (
                              <>
                                <small
                                  style={{
                                    color: "grey",
                                    whiteSpace: "pre-wrap",
                                    display: "block",
                                    marginTop: "-4px",
                                  }}
                                >
                                  {value.subtitle ? value.subtitle : " "}
                                </small>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                );
              })}
            {field.colors && (
              <Box
                sx={{
                  // resize: "horizontal",
                  overflow: "auto",
                  px: 2,
                  height: innerWidth > 800 ? "165px" : "135px",
                  display: "flex",
                  overflowY: "hidden",
                  margin:
                    innerWidth > 800
                      ? "-55px auto 0px auto"
                      : "-25px auto 6px auto",
                  "::-webkit-scrollbar": { display: "none" },
                  paddingLeft: field.choices
                    ? field.choices.length > 6
                      ? "20px"
                      : "auto"
                    : "auto",
                }}
              >
                {/* {id && <FadeInModal childrenHtml={infoModalText} open={infoModalOpen} setOpen={setInfoModalOpen}/>}
                 */}
                <Box
                  // aria-labelledby="product-color-attribute"
                  // defaultValue="warning"
                  sx={{
                    gap: 2,
                    // flexWrap: "wrap",
                    display: "flex",
                    flexDirection: "row",
                    height: innerWidth > 800 ? "auto" : "47px",
                    margin: "auto",
                    marginBottom: "47px",
                    overflow: "visible",
                    whiteSpace: "nowrap",
                  }}
                >
                  {field.subchoices.map((value) => {
                    return (
                      <Box
                        element={"span"}
                        sx={{
                          transition: "filter .1s ease-in-out",
                          height: "40px",
                          filter:
                            (currentValue[2] != value.rgb ||
                              currentValue[2] != value.material) &&
                            (!(
                              // .filter((l) => l.subtitle == currentValue[1])[0]
                              field.options.filter(
                                (i) => i.title == currentValue[0]
                              )[0].prohibitedCombinations
                            ) ||
                              (!field.options
                                .filter((i) => i.title == currentValue[0])[0]
                                // .filter((l) => l.subtitle == currentValue[1])[0]
                                .prohibitedCombinations?.includes(value.rgb) &&
                                !field.options
                                  .filter((i) => i.title == currentValue[0])[0]
                                  // .filter((l) => l.subtitle == currentValue[1])[0]
                                  .prohibitedCombinations?.includes(
                                    value.material
                                  )))
                              ? "none"
                              : "opacity(0.3)",
                          cursor:
                            (currentValue[2] != value.rgb ||
                              currentValue[2] != value.material) &&
                            !field.options
                              .filter((i) => i.title == currentValue[0])[0]
                              // .filter((l) => l.subtitle == currentValue[1])[0]
                              .prohibitedCombinations?.includes(value.rgb)
                              ? "pointer"
                              : "not-allowed",
                        }}
                      >
                        <Tooltip
                          sx={{
                            marginLeft: "-25px",
                            left: "-20px",
                            display: innerWidth > 800 ? "auto" : "none",
                          }}
                          title={
                            !(
                              (currentValue[2] != value.rgb ||
                                currentValue[2] != value.material) &&
                              !field.options
                                .filter((i) => i.title == currentValue[0])[0]
                                // .filter((l) => l.subtitle == currentValue[1])[0]
                                .prohibitedCombinations?.includes(value.rgb)
                            ) && "nur HomeCUBE"
                          }
                          placement="right"
                        >
                          <Box
                            element={"span"}
                            className={"color-circle"}
                            key={value.rgb}
                            onClick={() => {
                              // das hier muss vermutlich für rgb UND .material gebaut werden
                              if (
                                (currentValue[2] != value.rgb ||
                                  currentValue[2] != value.material) &&
                                !(
                                  field.options
                                    .filter(
                                      (i) => i.title == currentValue[0]
                                    )[0]
                                    // .filter((l) => l.subtitle == currentValue[1])[0]
                                    .prohibitedCombinations?.includes(
                                      value.rgb
                                    ) ||
                                  field.options
                                    .filter(
                                      (i) => i.title == currentValue[0]
                                    )[0]
                                    // .filter((l) => l.subtitle == currentValue[1])[0]
                                    .prohibitedCombinations?.includes(
                                      value.material
                                    )
                                )
                              ) {
                                setCurrentValue([
                                  currentValue[0],
                                  currentValue[1],
                                  value.rgb,
                                ]);
                              } else {
                                //setCurrentValue([currentValue[0], currentValue[1], currentValue[2]]);
                              }
                            }}
                            // onClick={() => {
                            //   if (
                            //     currentValue != value.rgb ||
                            //     !["menuConfiguration"].unselectOnClick
                            //   ) {
                            //     setCurrentValue(value.material ? value.material : value.rgb);
                            //   } else {
                            //     setCurrentValue(null);
                            //   }
                            // }}
                            sx={{
                              display: "inline-block",
                              border:
                                (currentValue &&
                                  currentValue[2] == value.title) ||
                                (currentValue &&
                                  currentValue[2] == value.material)
                                  ? // value.rgb == currentValue ||
                                    // value.material == currentValue
                                    "4px solid " +
                                    (currentMenu.branding
                                      ? currentMenu.branding.primaryColor.rgb
                                      : "blue")
                                  : "4px solid white",
                              borderColor:
                                value.rgb == currentValue[2] ||
                                value.material == currentValue[2]
                                  ? currentMenu.branding
                                    ? currentMenu.branding.primaryColor.rgb
                                    : "blue"
                                  : "white",
                              transition: "border .3s linear",
                              position: "relative",
                              width: 40,
                              height: 40,
                              flexShrink: 0,
                              // bgcolor: `${color}.solidBg`,
                              borderRadius: "50%",
                              display: "flex",
                              background: value.gradientRgb
                                ? "linear-gradient(-20deg, " +
                                  value.rgb +
                                  "," +
                                  value.gradientRgb +
                                  ")"
                                : value.rgb,
                              backgroundImage: value.backgroundImg
                                ? "url(' " + value.backgroundImg + " ')"
                                : null,
                              backgroundSize: "contain",
                              marginRight: value.title ? "25px" : "0",
                              marginLeft: value.title ? "29px" : "0",
                              marginBottom: value.title ? "30px" : "0",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor:
                                (currentValue[2] != value.rgb ||
                                  currentValue[2] != value.material) &&
                                !field.options
                                  .filter((i) => i.title == currentValue[0])[0]
                                  // .filter((l) => l.subtitle == currentValue[1])[0]
                                  .prohibitedCombinations?.includes(value.rgb)
                                  ? "pointer"
                                  : "not-allowed",
                              "&:hover": {
                                borderColor:
                                  value.rgb == currentValue[2] ||
                                  value.material == currentValue[2]
                                    ? currentMenu.branding
                                      ? currentMenu.branding.primaryColor.rgb
                                      : "blue"
                                    : "lightgrey",
                              },
                            }}
                          ></Box>
                        </Tooltip>
                        {value.title && (
                          <div
                            style={{
                              // marginBottom: "-80px",
                              marginTop: "-27px",
                              width: "100px",
                              textAlign: "center",
                              color: "#333",
                              fontSize: "0.85rem",
                              whiteSpace: "normal",
                              lineHeight: "0.7rem",
                            }}
                          >
                            <span
                              style={{
                                fontSize:
                                  value.title.length > 14
                                    ? "0.75rem"
                                    : "inherit",
                              }}
                            >
                              {value.verboseTitle
                                ? value.verboseTitle
                                : value.title}
                            </span>
                            {/* {value.info && <InfoIcon sx={{display: "inline",  "& :hover":{color: "black"}, color: "grey", cursor: "pointer", marginBottom: "-3px", marginLeft: "2px", fontSize: "0.9rem"}} onClick={()=>{setInfoModalText(value.info);setInfoModalOpen(true)}}/>} */}
                            {(value.price || value.price == 0) && (
                              <small
                                style={{ display: "block", color: "#666" }}
                              >
                                <CurrencyFormat
                                  suffix={" €"}
                                  displayType={"text"}
                                  decimalSeparator={","}
                                  thousandSeparator={"."}
                                  value={value.price}
                                />
                              </small>
                            )}
                          </div>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
