/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

const Locations = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={4} mt={4}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Box marginBottom={2}>
              <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
                Wir arbeiten remote für Kunden rund um den Globus
              </Typography>
              <Typography color="text.secondary">
                Configora ist ein "remote-first" Unternehmen mit Sitz in Tiflis (Georgien), dem Balkon Europas. Tiflis besitzt eine hervorragende digitale Infrastruktur und ist eine dynamische, pulsierende Stadt mit mediterranem Flair. 
              <br/><br/>Georgien ist weltoffen und pflegt enge wirtschaftliche Beziehungen mit Europa und den USA - insbesondere im IT-Sektor. 
              </Typography>
            </Box>
            {/* <Grid container spacing={1}>
              {[
                'All features',
                'Email support',
                'Google Ads',
                'SSO via Google',
                'API access',
                'Facebook Ads',
              ].map((item, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height={1} width={1} display={'flex'} flexDirection={'column'}>
            <Box
              component={'img'}
              src={'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/georgia_flag.jpg?alt=media&token=b00f06f5-faec-4c94-8ea6-08e50e18e4c8'}
              alt="..."
              width={160}
              height={160}
              marginLeft={'calc(60% - 160px)'}
              zIndex={3}
              borderRadius={'100%'}
              boxShadow={4}
              data-aos={'fade-up'}
              sx={{
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.5)' : 'none',
              }}
            />
            <Box
              component={'img'}
              width={200}
              height={200}
              src={'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/axis_tower.jpg?alt=media&token=52e10815-c967-45cc-b94b-0fde50af2f97'}
              alt="..."
              marginTop={'-8%'}
              zIndex={2}
              borderRadius={'100%'}
              boxShadow={4}
              data-aos={'fade-up'}
              sx={{
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.5)' : 'none',
              }}
            />
            <Box
              component={'img'}
              width={300}
              height={300}
              src={'https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/tbilisi.jpg?alt=media&token=9097f43f-a38f-45ea-b111-bed51dc337fb'}
              alt="..."
              marginTop={'-20%'}
              marginLeft={'calc(100% - 300px)'}
              zIndex={1}
              borderRadius={'100%'}
              boxShadow={4}
              data-aos={'fade-up'}
              sx={{
                objectFit: 'cover',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.5)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Locations;
