/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { primaryColor } from "Routes";
import { Checkbox, CircularProgress, Divider } from "@mui/joy";
import * as THREE from "three";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  sendSignInLinkToEmail,
  signOut,
} from "firebase/auth";
import { auth, db } from "index";
import { useNavigate, useParams } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { InputLabel, Link, accordionActionsClasses } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CheckBox, WifiFindTwoTone } from "@mui/icons-material";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { RWebShare } from "react-web-share";
import ClipboardCopy from "CopyButton";
import useWindowSize from "useWindowSize";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { toast } from "sonner";
import { useThree } from "@react-three/fiber";

var debounce = require("lodash.debounce");

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `https://ynflow.com/portal/my-configurations`,
  // This must be true.
  handleCodeInApp: true,
};

const converter = {
  toFirestore(post) {
    return {
      text: post.text,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

const ConfigForm = ({
  configuration,
  toggleSubmitted,
  storedConfig,
  reloadStoredConfig,
  parametrization,
  scene,
  price,
  deliveryCosts,
  deliveryDestination,
  toggleOpen,
  verboseConfiguration,
}) => {
  const [formSubmitted, toggleFormSubmitted] = useState(false);
  const theme = useTheme();

  let { id, configurationId } = useParams();
  const [fullInfoGiven, toggleFullInfoGiven] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [AGBs, toggleAGBs] = useState(false);
  const [DSGVO, toggleDSGVO] = useState(false);
  const [adress, setAdress] = useState("");
  const [loadingSubmit, toggleLoadingSubmit] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .min(2, "Bitte geben sie einen gültigen Vornamen an")
      .max(50, "Bitte geben sie einen gültigen Vornamen an")
      .required("Der Vorname ist ein Pflichtfeld"),
    lastName: yup
      .string()
      .trim()

      .max(50, "Bitte geben sie einen gültigen Nachnamen an")
      .required("Der Nachname ist ein Pflichtfeld"),
    phone: parametrization.phoneSubmissionMandatory
      ? yup
          .string()
          .min(5, "Bitte geben sie einen gültige Telefonnummer an")
          // .matches(phoneRegExp, "Telefonnummer ist ungültig")
          .required("Die Telefonnummer ist ein Pflichtfeld")
      : yup.string(),
    // .matches(phoneRegExp, "Telefonnummer ist ungültig")
    message: yup.string().trim(),
    email: yup
      .string()
      .trim()
      .matches()
      .min(6, "Bitte geben sie eine gültige E-Mail an")
      .email("Bitte geben sie eine gültige E-Mail an")
      .matches(emailRegex, "Bitte geben sie eine gültige E-Mail an")
      .required("Die E-Mail ist ein Pflichtfeld"),
  });

  let navigate = useNavigate();
  const [emailInUse, toggleEmailInUse] = useState(false);
  const innerWidth = useWindowSize();
  // const debouceRequest = useCallback((value) => debounced(value), []);
  useEffect(() => {
    if (storedConfig) {
      toggleFormSubmitted(storedConfig.submitted);
    }
  }, [storedConfig]);
  // useEffect(() => {
  //   console.log("confi: ", configuration);
  // }, [configuration]);
  // const debounced = debounce(async (value) => {

  //   const q = query(collection(db, "users").withConverter(converter), where("email", "==", value));
  //   const docSnap = await getDocs(q);
  //   if (docSnap.docs.length > 0 && docSnap.docs[0]) {
  //     console.log(docSnap.docs[0].data())
  //     toggleEmailInUse(true);
  //     setFirestoreUser(docSnap.docs[0].data());
  //     if (docSnap.docs[0].data().firstName && docSnap.docs[0].data().lastName) {
  //       toggleFullInfoGiven(true);
  //     } else {
  //       toggleFullInfoGiven(false);
  //     }
  //   } else {
  //     toggleEmailInUse(false);
  //     toggleFullInfoGiven(false);
  //   }
  // }, 1000);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  };

  const onSubmit = (values) => {
    // let payload = {
    //   ...values,
    //   ["referrer"]: (document.cookie.match(
    //     /^(?:.*;)?\s*MyCookie\s*=\s*([^;]+)(?:.*)?$/
    //   ) || [, "Direct"])[1],
    // };
    // fetch(
    //   "https://nethunt.com/service/automation/hooks/645e75da791f120008875b32",
    //   {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(payload),
    //   }
    // ).then((res) => {
    //   toggleFormSubmitted(true);
    // });
    // console.log(payload);
    // return payload;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  // useEffect(() => {
  //   if (emailInUse) {
  //     toggleEmailInUse(false);
  //   }
  // }, [formik.values.email]);

  if (!formSubmitted) {
    return (
      <Box id={"request-demo"}>
        <div style={{ maxWidth: "600px", paddingBottom: "40px" }}>
          <h2
            style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#25252d" }}
          >
            Konfiguration abschließen
          </h2>

          <Typography
            variant={"description"}
            sx={{
              display: "inline-block",
              marginBottom: "40px",
              fontSize: innerWidth < 800 ? "12px" : "16px",
            }}
          >
            <br />
            Fertig? Dann schließen Sie Ihre Konfiguration jetzt ab, damit wir
            einen ersten Eindruck von Ihren Vorstellungen erhalten.
            <br />
            <br /> Wir treten danach schnellstmöglich mit Ihnen in Kontakt und
            beraten Sie persönlich weiter.
          </Typography>
          {/* <Box marginBottom={2}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={'center'}
        >
          Can't find the answer you need?
        </Typography>
        <Typography color="text.secondary" align={'center'}>
          Keep track of what's happening with your data, change permissions, and
          run reports against your data anywhere in the world. Keep track of
          what's happening with your data, change permissions.
        </Typography>
      </Box> */}
          <Box
            //maxWidth={600}
            margin={"auto"}
            component={"form"}
            onSubmit={formik.handleSubmit}
            sx={{
              "& .MuiOutlinedInput-root.MuiInputBase-multiline": {
                padding: 0,
              },
              "& .MuiOutlinedInput-input": {
                background: theme.palette.background.paper,
                padding: 2,
              },
            }}
          >
            <Grid container spacing={isMd ? 4 : 2}>
              <Grid item xs={12} md={12}>
                {/* {!user ? ( */}
                <>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight={700}
                    gutterBottom
                    sx={{ fontSize: "1.3rem" }}
                  >
                    E-Mail*
                  </Typography>
                  <small style={{ color: "rgb(200,30,30)" }} for={"email"}>
                    {formik.errors.email}{" "}
                  </small>
                  <Input
                    placeholder="max@mustermann.de"
                    variant="outlined"
                    size="medium"
                    name="email"
                    fullWidth
                    type="email"
                    // onBlur={() =>
                    //   fetchSignInMethodsForEmail(auth, formik.values.email).then(
                    //     (result) => {
                    //       console.log("result", result);
                    //       if (result.length === 0) {
                    //         console.log("Email not in use");
                    //         toggleEmailInUse(false);
                    //       } else {
                    //         console.log("Email in use");

                    //         toggleEmailInUse(true);
                    //       }
                    //     }
                    //   )
                    // }

                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={
                      formik.handleChange
                      // debouceRequest(e.target.value);
                    }
                    sx={{ padding: "6px" }}
                  />
                  <div
                    className={"animate__animated animate__fadeIn"}
                    style={{ paddingTop: "30px" }}
                  >
                    {fullInfoGiven == true &&
                      formik.values.email.length > 5 && (
                        <b>Willkommen zurück!</b>
                      )}
                    {/* {!fullInfoGiven && formik.values.email.length > 0 && (
                      <b>
                        {emailInUse ? <>Sie sind registriert aber w</> : <>W</>}ir
                        kennen Sie noch nicht gut genug. Bitte geben Sie uns ein paar Informationen.
                      </b>
                    )} */}
                  </div>
                </>
                {/* ) : (
                <>
                  <Typography variant={"body1"}>
                    Eingeloggt als
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight={700}
                    gutterBottom
                    sx={{ fontSize: "1.3rem" }}
                  >
                    {user && user.email}
                  </Typography>
                  <Typography variant={"body1"} sx={{ float: "left" }}>
                    Nicht richtig?{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        signOut(auth);

                        try {
                          window.localStorage.removeItem("emailForSignIn");
                        } catch (error) {
                          console.log(error);
                        }
                        //navigate("/portal/my-configurations");
                      }}
                    >
                      Log out
                    </Link>
                    .
                  </Typography>
                </>
              )} */}
              </Grid>

              {!fullInfoGiven && formik.values.email.length > 5 && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={700}
                      gutterBottom
                      sx={{ fontSize: "1rem" }}
                    >
                      Vorname*
                    </Typography>
                    <small
                      style={{
                        color: formik.errors.firstName
                          ? "rgb(200,30,30)"
                          : "#555",
                      }}
                      for={"email"}
                    >
                      {formik.errors.firstName
                        ? formik.errors.firstName
                        : "Pflichtfeld"}{" "}
                    </small>{" "}
                    <Input
                      placeholder="Vorname"
                      variant="outlined"
                      size="medium"
                      name="firstName"
                      fullWidth
                      type="text"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      sx={{ padding: "6px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={700}
                      gutterBottom
                      sx={{ fontSize: "1rem" }}
                    >
                      Nachname*
                    </Typography>
                    <small
                      style={{
                        color: formik.errors.lastName
                          ? "rgb(200,30,30)"
                          : "#555",
                      }}
                      for={"email"}
                    >
                      {formik.errors.lastName
                        ? formik.errors.lastName
                        : "Pflichtfeld"}{" "}
                    </small>
                    <Input
                      placeholder="Nachname"
                      variant="outlined"
                      size="medium"
                      name="lastName"
                      fullWidth
                      type="text"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      sx={{ padding: "6px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={700}
                      gutterBottom
                      sx={{ fontSize: "1rem" }}
                    >
                      Firma
                    </Typography>
                    {parametrization.phoneSubmissionMandatory && (
                      <small>
                        <br />
                      </small>
                    )}
                    <Input
                      placeholder="Firma"
                      variant="outlined"
                      size="medium"
                      name="company"
                      fullWidth
                      type="text"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                      // error={formik.touched.company && Boolean(formik.errors.company)}
                      // helperText={formik.touched.company && formik.errors.company}
                      sx={{ padding: "6px" }}
                    />
                  </Grid>
                  {/* <Box sx={{borderTop: "1px solid #ccc", width: "100%", margin: "190px", marginTop: "55px", marginBottom: "15px" }}/>
                   */}

                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight={700}
                      gutterBottom
                      sx={{ fontSize: "1rem" }}
                    >
                      Telefon{parametrization.phoneSubmissionMandatory && "*"}
                    </Typography>
                    {parametrization.phoneSubmissionMandatory && (
                      <small
                        style={{
                          color: formik.errors.phone
                            ? "rgb(200,30,30)"
                            : "#555",
                        }}
                        for={"phone"}
                      >
                        {formik.errors.phone
                          ? formik.errors.phone
                          : "Pflichtfeld"}{" "}
                      </small>
                    )}
                    <Input
                      placeholder={"Telefonnummer"}
                      variant="outlined"
                      size="medium"
                      name="phone"
                      fullWidth
                      type="text"
                      sx={{ padding: "6px" }}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    {/* <br/><Typography variant={"body1"} sx={{color: "#666", textAlign: "left"}}>we will reach out only to book an appointment</Typography> */}
                  </Grid>
                  {!parametrization.disableAdressFields && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          fontWeight={700}
                          gutterBottom
                          sx={{ fontSize: "1rem" }}
                        >
                          Straße und Hausnummer
                        </Typography>
                        <Input
                          placeholder="Musterstraße 12"
                          variant="outlined"
                          size="medium"
                          name="streetAndNumber"
                          fullWidth
                          type="streetAndNumber"
                          sx={{ padding: "6px" }}
                          value={formik.values.streetAndNumber}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.streetAndNumber &&
                            Boolean(formik.errors.streetAndNumber)
                          }
                          helperText={
                            formik.touched.streetAndNumber &&
                            formik.errors.streetAndNumber
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          fontWeight={700}
                          gutterBottom
                          sx={{ fontSize: "1rem" }}
                        >
                          Adresszusatz
                        </Typography>
                        <Input
                          placeholder="Komplex 17"
                          variant="outlined"
                          size="medium"
                          name="addition"
                          fullWidth
                          type="addition"
                          sx={{ padding: "6px" }}
                          value={formik.values.addition}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.addition &&
                            Boolean(formik.errors.addition)
                          }
                          helperText={
                            formik.touched.addition && formik.errors.addition
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          fontWeight={700}
                          gutterBottom
                          sx={{ fontSize: "1rem" }}
                        >
                          Postleitzahl
                        </Typography>
                        <Input
                          placeholder="12345"
                          variant="outlined"
                          size="medium"
                          name="postalcode"
                          fullWidth
                          type="postalcode"
                          sx={{ padding: "6px" }}
                          value={formik.values.postalcode}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.postalcode &&
                            Boolean(formik.errors.postalcode)
                          }
                          helperText={
                            formik.touched.postalcode &&
                            formik.errors.postalcode
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          fontWeight={700}
                          gutterBottom
                          sx={{ fontSize: "1rem" }}
                        >
                          Stadt
                        </Typography>
                        <Input
                          placeholder="Berlin"
                          variant="outlined"
                          size="medium"
                          name="city"
                          fullWidth
                          type="city"
                          sx={{ padding: "6px" }}
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      </Grid>
                    </>
                  )}
                  {/* <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight={700}
                    gutterBottom
                    sx={{ fontSize: "1rem" }}
                  >
                    Nachricht (optional)
                  </Typography>
                  <Input
                    placeholder="Hallo, ich habe noch ein paar offene Fragen..."
                    variant="outlined"
                    name="message"
                    fullWidth
                    multilinef
                    rows={2}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                    sx={{ padding: "6px" }}
                  />
                </Grid> */}
                  {/* <Grid item xs={12}>
                <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight={700}
                    gutterBottom
                    sx={{ fontSize: "1rem" }}
                  >
                    Adresse
                  </Typography>
                  <GooglePlacesAutocomplete
                  autocompletionRequest={{types: ["premise"]}}
                   onChange={e => console.log("Adress selected: ", e)} selectProps={{
    value: adress? {value: adress, label: adress}:false,
    onChange: e => {
      if(e == null){
        setAdress("")
      }else{
      console.log(e);geocodeByPlaceId(e.value.place_id)
    .then(results => {console.error(results);setAdress(results[0].formatted_address)})
    .catch(error => console.error(error));}},
    placeholder: "Musterweg 15, 12345 Musterstadt",
    isClearable: true,
    backspaceRemovesValue: true,
    styles:{
      placeholder: (provided, state) => ({
        ...provided,
        display: state.isFocused ? 'none' : undefined,
    })
  }
  }}
   placeholder={"Adresse"} apiKey={"AIzaSyB7clkCriU1xZL6lve7eHS_9w6bhMzigws"}
                  />
              
                </Grid> */}

                  <Grid item xs={12}>
                    <Checkbox
                      checked={DSGVO}
                      onClick={() => toggleDSGVO(!DSGVO)}
                      label={
                        <span>
                          Ich akzeptiere die{" "}
                          <a
                            style={{ zIndex: 1000, position: "relative" }}
                            href={parametrization.datenschutz}
                            target={"_blank"}
                          >
                            Datenschutzerklärung
                          </a>
                          .
                        </span>
                      }
                    />
                  </Grid>
                  {false && (
                    <Grid item xs={12}>
                      <Checkbox
                        checked={AGBs}
                        onClick={() => toggleAGBs(!AGBs)}
                        label={
                          <span>
                            Ich akzeptiere die{" "}
                            <a href={parametrization.agb} target={"_blank"}>
                              allgemeinen Geschäftsbeindungen
                            </a>
                            .
                          </span>
                        }
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  disabled={
                    emailInUse
                      ? false
                      : loadingSubmit ||
                        formik.errors.email ||
                        formik.errors.firstName ||
                        formik.errors.lastName ||
                        formik.errors.phone ||
                        !DSGVO
                    // ||!AGBs
                  }
                  size="md"
                  sx={{
                    "&:hover": {
                      backgroundPosition: "right center",
                      // backgroundColor: !submitted
                      //   ? props.parametrization.branding.primaryColor.rgb
                      //   : "green",
                      backgroundColor: "white",
                    },

                    backgroundColor: "white",
                    // backgroundImage: !submitted
                    //   ? `linear-gradient(to right, ${props.parametrization.branding.primaryColor.rgb} 0%,
                    //     rgba(${props.parametrization.branding.primaryColor.r},${props.parametrization.branding.primaryColor.g},${props.parametrization.branding.primaryColor.b}, 0.4) 51%, ${props.parametrization.branding.primaryColor.rgb} 100%)`
                    //   : "green",
                    // backgroundSize: "200% auto",
                    padding: "10px 20px 10px 20px",
                    fontSize: "1rem",
                    minHeight: "30px",
                    transition: "0.5s",
                    borderRadius: "24px",
                    zIndex: 1500,
                    color: "#333",
                    fontWeight: 400,
                    borderRadius: parametrization.branding?.borderRadius
                      ? parametrization.branding.borderRadius
                      : "22px",
                    // fontFamily: "'Lato', sans-serif",
                    border: "1px solid #bbb",
                    // marginBottom: "-200px",
                    "&:disabled": {
                      color: "#888",
                      background: "#eee",
                      cursor: "not-allowed",
                    },
                  }}
                  variant="contained"
                  //type="submit"

                  onClick={() => {
                    parametrization.GTM_ids &&
                      parametrization.GTM_ids.map((id, key) => {
                        let dataLayer = window["dataLayer" + key] || [];
                        // console.log("pushing to", dataLayer);

                        dataLayer.push({
                          event: "yn_submit",
                          configurator: parametrization.id,
                        });
                      });
                    console.log(
                      "submit clicked!",
                      auth,
                      formik.values.email,
                      actionCodeSettings
                    );
                    toggleLoadingSubmit(true);
                    if (configurationId) {
                      setDoc(
                        doc(db, "configurations", configurationId),
                        {
                          submitted: true,
                          parametrizationId: id,
                          lastName: formik.values.lastName,
                          firstName: formik.values.firstName,
                          email: formik.values.email,
                          phone: formik.values.phone ? formik.values.phone : "",
                          streetAndNumber: formik.values.streetAndNumber
                            ? formik.values.streetAndNumber
                            : "",
                          city: formik.values.city ? formik.values.city : "",
                          postalcode: formik.values.postalcode
                            ? formik.values.postalcode
                            : "",
                          addition: formik.values.addition
                            ? formik.values.addition
                            : "",
                          company: formik.values.company
                            ? formik.values.company
                            : "",
                          // userId: firestoreUser.id,
                          comment: formik.values.message
                            ? formik.values.message
                            : "",
                          calculatedPrice: price,
                          deliveryCosts: deliveryCosts,
                          deliveryDestination:deliveryDestination,
                          createdTimestamp: new Date().toISOString(),
                          lastUpdateTimestamp: new Date().toISOString(),
                          data: configuration.reduce(
                            (acc, cur) => ({
                              ...acc,
                              [cur.title]: cur.currentValue,
                            }),
                            {}
                          ),
                        },
                        { merge: true, ignoreUndefinedProperties: true }
                      ).then((ref) => {
                        toggleSubmitted(true);
                        //navigate(`/experience/${id}/${ref.id}`);
                        fetch(parametrization.submissionWebhook, {
                          method: "POST",
                          body: JSON.stringify({
                            ...configuration,
                            ["configurationId"]: ref ? ref.id : configurationId,
                            ...formik.values,
                            ...verboseConfiguration,
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                          },
                        });
                        toggleFormSubmitted(true);
                        try {
                          //reloadStoredConfig();
                        } catch (e) {}
                        toggleLoadingSubmit(false);
                      });
                    } else {
                      console.log("SUBMITTING WITH PRICE", price)
                      addDoc(
                        collection(db, "configurations"),
                        {
                          submitted: true,
                          parametrizationId: id,
                          lastName: formik.values.lastName,
                          firstName: formik.values.firstName,
                          email: formik.values.email,
                          phone: formik.values.phone ? formik.values.phone : "",
                          streetAndNumber: formik.values.streetAndNumber
                            ? formik.values.streetAndNumber
                            : "",
                          city: formik.values.city ? formik.values.city : "",
                          postalcode: formik.values.postalcode
                            ? formik.values.postalcode
                            : "",
                          addition: formik.values.addition
                            ? formik.values.addition
                            : "",
                          company: formik.values.company
                            ? formik.values.company
                            : "",
                          // userId: firestoreUser.id,
                          comment: formik.values.message
                            ? formik.values.message
                            : "",
                          createdTimestamp: new Date().toISOString(),
                          lastUpdateTimestamp: new Date().toISOString(),
                          calculatedPrice: price,
                          deliveryCosts: deliveryCosts,
                          deliveryDestination:deliveryDestination,
                          data: configuration.reduce(
                            (acc, cur) => ({
                              ...acc,
                              [cur.title]: cur.currentValue,
                            }),
                            {}
                          ),
                        },
                        { ignoreUndefinedProperties: true }
                      ).then((ref) => {
                        toggleSubmitted(true);

                        navigate(`/experience/${id}/${ref.id}`);

                        fetch(parametrization.submissionWebhook, {
                          method: "POST",
                          body: JSON.stringify({
                            ...verboseConfiguration,
                            ...configuration.map((k) => {
                              return {
                                ...k,
                                ["currentValue"]:
                                  k.inputType == "multiSelectAmounts"
                                    ? Object.keys(k["currentValue"]).map(
                                        (key) =>
                                          k["currentValue"][key] + " x " + key
                                      )
                                    : k.inputType == "multiSelect"
                                    ? k["currentValue"].map((v) => {
                                        let vt = k["options"].filter(
                                          (t) => t.title == v
                                        )[0]?.verboseTitle;
                                        if (vt) {
                                          return vt;
                                        } else {
                                          return v;
                                        }
                                      })
                                    : k.inputType == "color"
                                    ? k["choices"].filter(
                                        (t) =>
                                          t.rgb == k["currentValue"] ||
                                          t.material == k["currentValue"]
                                      )[0].title
                                    : (
                                        k["options"] ||
                                        k["choices"] ||
                                        []
                                      ).filter(
                                        (t) => t.title == k["currentValue"]
                                      )[0]?.webhookValue ||
                                      (
                                        k["options"] ||
                                        k["choices"] ||
                                        []
                                      ).filter(
                                        (t) => t.title == k["currentValue"]
                                      )[0]?.verboseTitle ||
                                      k["currentValue"],
                              };
                            }),
                            ["configurationId"]: ref.id,
                            ...formik.values,
                          }),
                          headers: {
                            "Content-type": "application/json; charset=UTF-8",
                          },
                        });

                        toggleFormSubmitted(true);
                        //reloadStoredConfig();
                        toggleLoadingSubmit(false);
                        toggleSubmitted(true);
                      });
                    }
                  }}
                >
                  {!loadingSubmit ? (
                    <>
                      Fertig{" "}
                      <ArrowRightAltIcon
                        strokeWidth={1}
                        sx={{ marginBottom: "-2px", marginLeft: "3px" }}
                      />
                    </>
                  ) : (
                    <>
                      Lade <CircularProgress />
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    );
  } else {
    return (
      <Box sx={{ p: 2, pt: 0 }}>
        <center>
          <h5
            className={
              "MuiTypography-root MuiTypography-h5 css-10icgz2-MuiTypography-root"
            }
            style={{ fontSize: "24px", fontWeight: 500, marginTop: "15px" }}
          >
            Vielen Dank!
            {parametrization.branding.du
              ? " Wir haben Deine Konfiguration erhalten!"
              : " Wir haben Ihre Konfiguration erhalten!"}
            <br />
          </h5>

          <div>
            <Box>
              {parametrization.branding.du
                ? "Teile Deine Konfiguration mit Deinen Freunden"
                : "Teilen Sie Ihre Konfiguration mit Ihren Freunden"}
            </Box>
            <br />
            {/* <div style={{ marginBottom: "15px" }}>
              <ClipboardCopy
                parametrization={parametrization}
                copyText={`${
                  parametrization.branding.customDomain
                    ? parametrization.branding.customDomain
                    : "https://ynflow.com"
                }/experience/${id}/${configurationId}`}
              />
            </div> */}
            <RWebShare
              data={{
                text: "Schau Dir meine Konfiguration an! Was hältst Du davon?",
                url: `${
                  parametrization.branding.customDomain
                    ? parametrization.branding.customDomain
                    : "https://ynflow.com"
                }/experience/${id}/${configurationId}`,
                title: "Konfiguration teilen",
              }}
              onClick={() => console.info("share successful!")}
            >
              <Button
                variant="contained"
                sx={{
                  background: parametrization.branding.primaryColor.rgb,
                  color: "white",
                  "&:hover": {
                    background: parametrization.branding.primaryColor.rgb,
                  },
                  width: "50%",
                  width: "270px",
                  borderRadius: parametrization.branding.borderRadius
                    ? parametrization.branding.borderRadius
                    : "auto",
                }}
              >
                Teilen
              </Button>
            </RWebShare>
          </div>

          {parametrization.nextFunnelStepHtml && (
            <div
              dangerouslySetInnerHTML={{
                __html: parametrization.nextFunnelStepHtml,
              }}
            ></div>
          )}
          <Typography variant={"h5"}>
            <a
              onClick={() =>
                parametrization.nextFunnelStepUrl &&
                (window.top.location.href = parametrization.nextFunnelStepUrl)
              }
            >
              <Button
                size={"large"}
                variant={"container"}
                //onClick={()=>{navigate("/experience/"+parametrization.id); window.location.reload()}}
                sx={{
                  minHeight: "2.5rem",
                  background: parametrization.branding.primaryColor.rgb,
                  color: "white",
                  "&:hover": {
                    background: parametrization.branding.primaryColor.rgb,
                  },
                  width: "50%",
                  width: "270px",
                  borderRadius: parametrization.branding.borderRadius
                    ? parametrization.branding.borderRadius
                    : "auto",
                }}
              >
                Weiter zur Website
              </Button>
            </a>
          </Typography>
          {/* <Typography variant={"h5"} sx={{ mt: "70px" }}>
            Möchtest Du eine weitere Konfiguration erstellen?
          </Typography> */}
          {/* <a href={"/experience/" + parametrization.id}>
            <Button
              size={"large"}
              variant={"container"}
              //onClick={()=>{navigate("/experience/"+parametrization.id); window.location.reload()}}
              sx={{
                background: parametrization.branding.primaryColor.rgb,
                padding: "10px",
                marginTop: "30px",
                color: "white",
                fontWeight: "normal",
                fontSize: "1rem",
                width: "270px",
                borderRadius: parametrization.branding.borderRadius
                  ? parametrization.branding.borderRadius
                  : "auto",
              }}
            >
              Neu starten
            </Button>
          </a> */}

          {/* <center style={{ margin: "20px 0" }}>
            <small>oder</small>
          </center> */}
          {/* <Button
            sx={{
              background: parametrization.branding.primaryColor.rgb,
              //padding: "20px",
              //marginTop: "30px",
              color: "white",
              fontWeight: "normal",
              fontSize: "1rem",
              width: "270px",
              "&:hover": {
                background: parametrization.branding.primaryColor.rgb,
              },
              borderRadius: parametrization.branding.borderRadius
                ? parametrization.branding.borderRadius
                : "auto",
            }}
            onClick={() => {
              parametrization.GTM_ids &&
                parametrization.GTM_ids.map((id, key) => {
                  let dataLayer = window["dataLayer" + key] || [];
                  // console.log("pushing to", dataLayer);

                  dataLayer.push({
                    event: "yn_duplicate",
                    configurator: parametrization.id,
                  });
                });
              //toggleShowLoadingSpinner(true);
              //console.log(email, actionCodeSettings, auth);
              // sendSignInLinkToEmail(
              //   auth,
              //   email,
              //   actionCodeSettings
              // ).then((data) => {
              //window.localStorage.setItem("emailForSignIn", email);

              addDoc(collection(db, "configurations"), {
                submitted: false,
                parametrizationId: id,
                comment: "",
                createdTimestamp: new Date().toISOString(),
                lastUpdateTimestamp: new Date().toISOString(),
                data: configuration.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.title]: cur.currentValue,
                  }),
                  {}
                ),
              }).then((docRef) => {
                if (window.location.href.indexOf("embed") > -1) {
                  //navigate(`/embed/${id}/${docRef.id}`);
                  window.open(`/predef/${id}/${docRef.id}`);
                } else {
                  //navigate(`/experience/${id}/${docRef.id}`);
                  window.open(`/predef/${id}/${docRef.id}`);
                }
                if (parametrization.screenshotCam) {
                  const storage = getStorage();
                  let renderer = new THREE.WebGLRenderer({
                    antialias: true,
                    preserveDrawingBuffer: true,
                  });
                  renderer.setClearColor(0x000000, 0);
                  renderer.setSize(800, 500);
                  let camera = new THREE.PerspectiveCamera(
                    70,
                    800 / 500,
                    1,
                    1000
                  );
                  camera.position.z = parametrization.screenshotCam.positionZ; //1.5;
                  camera.position.y = parametrization.screenshotCam.positionY; //0.6;
                  camera.position.x = parametrization.screenshotCam.positionX; //5.4;
                  camera.rotation.y = parametrization.screenshotCam.rotationY; //1.3;
                  // camera.rotation.x = -0.2;
                  camera.setFocalLength(45);
                  // camera.zoom=5;
                  // should only be called when there really is a
                  try {
                    // save screenshot to firestore storage
                    //console.log("saving screenshot!", props);

                    //var w = window.open('', '');
                    //w.document.title = "Screenshot";
                    //w.document.body.style.backgroundColor = "red";
                    var img = new Image();
                    // Without 'preserveDrawingBuffer' set to true, we must render now
                    // The call of render method causes memory leak :-(
                    renderer.render(scene, camera);
                    img.src = renderer.domElement.toBlob(function (blob) {
                      var image = new Image();
                      image.src = blob;
                      const storageRef = ref(storage, `${docRef.id}`);
                      uploadBytes(storageRef, blob)
                        .then((snapshot) => {
                          return getDownloadURL(snapshot.ref);
                        })
                        .then((downloadURL) => {
                          console.log("url: ", downloadURL);
                          const docRef1 = setDoc(
                            doc(db, "configurations", docRef.id),
                            {
                              img: downloadURL,
                            },
                            { merge: true }
                          );
                        });
                      // renderer = null;
                      //     // renderer.domElement.remove();
                      //   // var uploadTask = storageRef.child('images/' + configuration.id).put(blob);
                    });
                    toggleOpen(false);
                    //w.document.body.appendChild(img);
                  } catch (e) {
                    console.log(e);
                  }
                }
                //toggleShowLoadingSpinner(false);
                //props.toggleConfigDelta(false);
                //setOpen(false);
                //props.setOpen(false);
                toast("Duplizieren war erfolgreich!", {
                  //description: 'Monday, January 3rd at 6:00pm',
                  duration: 3000,
                  id: "saving-successfull",
                  style: {
                    background: "rgba(30,180,30,0.92)",
                    color: "rgba(255,255,255,1)",
                    margin: innerWidth < 800 ? "0" : "auto",
                    zIndex: 500,
                    lineHeight: "1.2",
                    fontSize: innerWidth < 800 ? "0.6rem" : "0.76rem",
                    position: "relative",
                    padding: "8px 12px 8px 12px",
                    textAlign: "center",
                    border: 0,
                    width: innerWidth < 800 ? "auto" : "300px",
                    userSelect: "none",
                    display: "block",
                  },
                  className: "my-toast",
                  descriptionClassName: "my-toast-description",
                });
              });

              // });
            }}
          >
            Konfiguration duplizieren
          </Button> */}
        </center>
      </Box>
    );
  }
};

export default ConfigForm;
