import React, { useRef, useState, Suspense } from "react";
import "./style.css";
import {
  Routes as ReactRoutes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import { createTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from "@mui/material/styles";
import { NotFoundCover } from "views";
import { SimpleSignInForm } from "blocks/authentication";
import "animate.css";
import { Account } from "blocks/formLayouts";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "index";
import { app } from "index";
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

import ErrorBoundary from "ErrorBoundary";
import CartSpeed from "icons/cart-speed";
import VirtualReality from "icons/virtual-reality";
import PeopleNetwork from "icons/people-network";
import Cogwheel from "icons/cogwheel";
import NetworkCommunication from "icons/network-communication";
import PortalIntegrations from "views/Account/Integrations/PortalIntegrations";
import ChartBar33 from "icons/chart-bar-33";
import loadable from "@loadable/component";
import { TourProvider } from "@reactour/tour";
import Warum3d from "views/CloudHosting/components/Warum3d";
import ARView from "ARView";

const materialTheme = materialExtendTheme();

export const THEME = createTheme({
  // overrides: {
  //   MuiTooltip: {
  //     tooltip: {
  //       fontSize: "2em",
  //       color: "yellow",
  //       backgroundColor: "red"
  //     }
  //   }
  // },
  typography: {
    fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    color: "#888",
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
  },
  palette: {
    primary: {
      main: "rgb(0,0,255)",
    },
    alternate: {
      main: "rgb(255, 0, 0)",
    },
    error: {
      main: "rgb(255,0,0)",
    },
  },
  OutlinedInput: {
    shape: {
      borderRadius: 4,
    },
  },
  button: {
    shape: {
      borderRadius: 4,
    },
  },
  shape: {
    borderRadius: 4,
  },
});

export const primaryColorR = "0";
export const primaryColorG = "72";
export const primaryColorB = "184";
export const primaryColor = `rgb(${primaryColorR},${primaryColorG},${primaryColorB})`;

export const PrimaryColor = () => {
  let { id } = useParams();
  const [parametrization, loading, error] = useDocumentDataOnce(
    doc(getFirestore(app), "parametrizations", id).withConverter(converter)
  );
  if (parametrization) {
    return {
      primaryColor: `rgb(${parametrization.branding.primaryColor.r},${parametrization.branding.primaryColor.g},${parametrization.branding.primaryColor.b})`,
      primaryColorR: parametrization.branding.primaryColor.r,
      primaryColorG: parametrization.branding.primaryColor.g,
      primaryColorB: parametrization.branding.primaryColor.b,
    };
  }
};

const portalPages = [
  {
    id: "sales",
    href: "/portal/sales",
    title: "Sales",
    icon: (
      <span style={{ marginLeft: "3px" }}>
        <CartSpeed width={"30px"} strokewidth={3} />
      </span>
    ),
  },
  // {
  //   id: "analytics",
  //   href: "/portal/analytics",
  //   title: "Analytics",
  //   icon: <span style={{marginLeft: "3px"}}><ChartBar33 width={"30px"} strokewidth={4}/></span>
  // },
  // {
  //   id: "my-configurations",
  //   href: "/portal/my-configurations",
  //   title: "My Configurations",
  //   icon: <span style={{marginLeft: "3px"}}><VirtualReality width={"30px"} strokewidth={4}/></span>
  // },
  // {
  //   id: "visitors",
  //   href: "/portal/visitors",
  //   title: "Visitors",
  //   icon: <span style={{marginLeft: "3px"}}><PeopleNetwork width={"30px"} strokewidth={4}/></span>
  // },
  // {
  //   id: "account",
  //   href: "/portal/account",
  //   title: "Account",
  //   icon: <span style={{marginLeft: "3px"}}><Cogwheel width={"30px"} strokewidth={4}/></span>
  // },
  // {
  //   id: "integrations",
  //   href: "/portal/integrations",
  //   title: "Integrations (comming soon)",
  //   icon: <span style={{marginLeft: "1px"}}><NetworkCommunication width={"30px"} strokewidth={4}/></span>
  // },
  // {
  //   id: "security",
  //   href: "portal/account-security",
  //   title: "Security",
  // },
  // {
  //   id: "notifications",
  //   href: "/portal/account-notifications",
  //   title: "Notifications",
  // },
  // {
  //   id: "billing",
  //   href: "/portal/account-billing",
  //   title: "Billing Information",
  // },
];

const portalPagesUser = [portalPages[1], portalPages[3]];

const converter = {
  toFirestore(post) {
    return {
      text: post.text,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

const CloudHostingLoadable = React.lazy(() =>
  import("../src/views/CloudHosting/CloudHosting")
);
const CalculatorLoadable = loadable(() =>
  import("../src/views/CloudHosting/components/Calculator")
);
const DemoLoadable = loadable(() =>
  import("../src/views/CloudHosting/components/Demo")
);
const ConfigLoadable = loadable(() => import("./Config"));
const PageLoadable = loadable(() =>
  import("./views/Account/components/Page/Page")
);
const SalesLoadable = loadable(() => import("./views/Account/Sales/Sales"));
const MyConfigurationsLoadable = loadable(() => import("./MyConfigurations"));
const VisitorsLoadable = loadable(() =>
  import("./views/Account/Visitors/Visitors")
);
const BlogArticleLoadable = loadable(() =>
  import("views/BlogArticle/BlogArticle")
);
const PreconfigurationDisplayLoadable = loadable(() =>
  import("./PreconfigurationDisplay")
);

const Routes = (props) => {
  const [configuration, setConfiguration] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [firestoreUser, firestoreUserLoading, firestoreUserError] =
    useCollectionData(
      user
        ? query(
            collection(db, "users").withConverter(converter),
            where("email", "==", user.email)
          )
        : null,
      [],
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );
  let navigate = useNavigate();

  const ref = useRef(null);
  // useEffect(()=>{
  //   if(user){
  //     console.log(user)

  //   }
  // },[user])
  if (!loading && !firestoreUserLoading && !firestoreUserError) {
    return (
      <ReactRoutes>
        {(window.location.hostname == "localhost" ||
          window.location.hostname == "ynflow.com" ||
          window.location.hostname == "configora.de" ||
          window.location.hostname == "configora.com" ||
          window.location.hostname == "www.configora.com" ||
          window.location.hostname == "www.configora.de") && (
          <>
            <Route
              path={"/"}
              element={
                <Suspense
                  fallback={
                    <div
                      id="logo-loading"
                      // class="animate__animated animate__fadeOut animate__faster"
                      style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        display: "flex",
                        left: 0,
                        top: 0,
                      }}
                    >
                      <img
                        id="lgo"
                        width="130px"
                        style={{ margin: "auto" }}
                        src="/c_logo.png"
                      />
                    </div>
                  }
                >
                  <CloudHostingLoadable />
                </Suspense>
              }
            />
            <Route path={"/calculator"} element={<CalculatorLoadable />} />
          </>
        )}

        {window.location.hostname == "konfigurator.ynbound.de" && (
          <Route
            path={"/"}
            element={<Navigate to="/experience/CTj8E6bpuY0KwNBH49w8" />}
          ></Route>
        )}
        {window.location.hostname == "3d.cube-fx.de" && (
          <Route
            path={"/"}
            element={<Navigate to="/experience/2NSNN8FBJxlNUq98Liix" />}
          ></Route>
        )}
        {window.location.hostname == "wohnen-office.garten-kubus.de" && (
          <Route
            path={"/"}
            element={<Navigate to="/experience/ALix3WF4dSsrubYu2NBH" />}
          ></Route>
        )}
        {window.location.hostname == "3d.walkingboxes.de" && (
          <Route
            path={"/"}
            element={<Navigate to="/experience/xhnglL5ZUs6mxoYv3eMI" />}
          ></Route>
        )}
        {window.location.hostname == "buy.klangschild.de" && (
          <Route
            path={"/"}
            element={<Navigate to="/experience/RjC8Ss0qnMrIjseH0VSp" />}
          ></Route>
        )}

        <Route path={"/article/:slug"} element={<BlogArticleLoadable />} />
        <Route path={"/ar/:configurationId"} element={<ARView />} />
        <Route path={"/demo"} element={<DemoLoadable />} />
        <Route exact path={"/login"} element={<SimpleSignInForm />}></Route>
        <Route exact path={"/warum-3d"} element={<Warum3d />}></Route>
        {firestoreUser && (
          // <Route
          //   path={"/portal"}
          //   element={
          //     !loading &&
          //     user &&
          //     !firestoreUserLoading &&
          //     !firestoreUserError &&
          //     firestoreUser ? (
          //       firestoreUser[0].permissions ? (
          //         <Page pages={portalPages} />
          //       ) : (
          //         <Navigate to="/my-configurations" />
          //       )
          //     ) : (
          //       <Navigate to="/login" />
          //     )
          //   }
          // >

          <Route
            path={"/portal"}
            element={
              <PageLoadable
                pages={
                  !loading &&
                  user &&
                  !firestoreUserLoading &&
                  !firestoreUserError &&
                  firestoreUser ? (
                    firestoreUser[0].permissions ? (
                      portalPages
                    ) : (
                      portalPagesUser
                    )
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            }
          >
            <Route path={"account"} element={<Account />} />
            <Route
              path={"sales/:parametrizationId?"}
              element={<SalesLoadable />}
            />
            <Route
              path={"analytics"}
              element={
                <iframe
                  src="https://app.chartbrew.com/b/Analytics_Demo_7676"
                  allowTransparency="true"
                  width="100%"
                  height="849"
                  frameborder="0"
                  style={{ backgroundColor: "#ffffff", borderRadius: "20px" }}
                ></iframe>
              }
            />
            <Route path={"visitors"} element={<VisitorsLoadable />} />
            <Route
              path={"my-configurations"}
              element={<MyConfigurationsLoadable />}
            />
            <Route path={"integrations"} element={<PortalIntegrations />} />
          </Route>
        )}

        <Route
          exact
          path="/experience/:id"
          element={
            // !user ? (
            // <Suspense fallback={<>Now Loading sth....</>}>
            <ConfigLoadable
              configuration={configuration}
              setConfiguration={setConfiguration}
              modalRef={ref}
            ></ConfigLoadable>

            // </Suspense>
            // ) : (
            //   <Navigate to="/portal/my-configurations" />
            // )
          }
        />
        <Route
          exact
          path="/embed/:id"
          element={
            // !user ? (
            // <Suspense fallback={<>Now Loading sth....</>}>
            <ConfigLoadable
              configuration={configuration}
              setConfiguration={setConfiguration}
              modalRef={ref}
              embedded
            ></ConfigLoadable>
            // </Suspense>
            // ) : (
            //   <Navigate to="/portal/my-configurations" />
            // )
          }
        />
        <Route
          exact
          path="/predef/:id/:predefId"
          element={
            // !user ? (
            // <Suspense fallback={<>Now Loading sth....</>}>
            <ConfigLoadable
              configuration={configuration}
              setConfiguration={setConfiguration}
              modalRef={ref}
              predef
            ></ConfigLoadable>
            // </Suspense>
            // ) : (
            //   <Navigate to="/portal/my-configurations" />
            // )
          }
        />
        <Route
          exact
          path="/embed/:id/:configurationId"
          element={
            // !user ? (
            // <Suspense fallback={<>Now Loading sth....</>}>
            <ConfigLoadable
              configuration={configuration}
              setConfiguration={setConfiguration}
              modalRef={ref}
              embedded
            ></ConfigLoadable>
            // </Suspense>
            // ) : (
            //   <Navigate to="/portal/my-configurations" />
            // )
          }
        />
        <Route
          exact
          path="/experience/:id/:configurationId"
          element={
            // <Suspense fallback={<>Now Loading sth....</>}>
            <ConfigLoadable
              configuration={configuration}
              setConfiguration={setConfiguration}
              modalRef={ref}
            ></ConfigLoadable>
            // </Suspense>
          }
        />
        <Route
          exact
          path="/display/:preconfigurationDisplayId"
          element={
            // <Suspense fallback={<>Now Loading sth....</>}>
            // <ConfigLoadable
            //   configuration={configuration}
            //   setConfiguration={setConfiguration}
            //   modalRef={ref}
            // ></ConfigLoadable>
            <PreconfigurationDisplayLoadable />
            // </Suspense>
          }
        />
        <Route
          path="/my-configurations"
          element={
            <ErrorBoundary>
              <MyConfigurationsLoadable />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<NotFoundCover />} />
      </ReactRoutes>
    );
  } else {
    return <></>;
  }
};

export default Routes;
