import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { ClearOutlined, DoNotDisturbAltOutlined } from "@mui/icons-material";
const mock1 = [
  {
    title: "Viele Emails und langwierige Telefonate mit Kunden aber wenig Umsatz",
    description:
      "Du schaffst es nicht, Kunden schnell umfassend zu informieren und für Dein Produkt zu begeistern, sondern schreckst sie noch zusätzlich mit umständlichen PDFs und Formularen ab. Notwendige Informationen müssen wie Puzzlestücke aus der Website, PDFs und Emails zusammengesammelt werden. Damit verlierst Du täglich wichtige Kunden.",
    illustration:
      // "/undraw_lost_re_xqjt.svg",
      "/undraw_throw_away_re_x60k.svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration4--dark.svg",
  },
  {
    title: "Kunden brauchen Wochen und Monate bis sie sich entscheiden",
    description:
      "Wenn Kunden lange (> 3 Stunden) auf Ihr Angebot warten müssen, dann ist das eine Aufforderung, sich bei Deinen zahlreichen Mitbewerbern umzuschauen. Wenn diese schneller ein Angebot machen, dann ist der Kunde dort einen Schritt weiter und wird sich nicht mehr für Dich interessieren. Der Umsatz ist damit verloren.",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  {
    title: "Du bist unter Preisdruck denn die Konkurrenz ist groß",
    description:
      "Interessenten wollen weder Geld noch Zeit verschwenden. Die meisten Hersteller schaffen es nicht, die hohe Qualität Ihrer Produkte zu transportieren und was sie vom Marktführer unterscheidet. Dadurch gehen Kunden lieber auf Nummer sicher und kaufen beim Branchenprimus.",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  {
    title: "Wichtige Dinge im Beruf und Privatleben bleiben auf der Strecke. Du bist im Hamsterrad gefangen.",
    description:
      "Interessenten wollen weder Geld noch Zeit verschwenden. Die meisten Hersteller schaffen es nicht, die hohe Qualität Ihrer Produkte zu transportieren und was sie vom Marktführer unterscheidet. Dadurch gehen Kunden lieber auf Nummer sicher und kaufen beim Branchenprimus.",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  // {
  //   label: "Client portal access",
  //   title: "Chaos in Vertrieb und Marketing durch die falschen Tools, undurchsichtige Excel-Sheets oder sogar Zettelwirtschaft",
  //   description:
  //     "Chaos sorgt dafür, dass Kundenanfragen nicht schnell oder nicht wirksam bearbeitet werden und der Umsatz an Deinen Konkurrenten geht, der es besser macht.",
  //   illustration:
  //     "/undraw_abstract_re_l9xy.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  // {
  //   label: "Client portal access",
  //   title: "Keine systematische Datenerhebung in Marketing und Vertrieb",
  //   description:
  //     "Dadurch bleiben Fehler in Vertrieb und Marketing unentdeckt und Du bleibst langfristig unter Deinen Umsatzmöglichkeiten.",
  //   illustration:
  //     "/undraw_logic_re_nyb4.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  
];
const mock = [
  {
    title: "Du nutzt ein simples Anfrageformular, Bilder, Texte und PDFs zum Verkaufen",
    description:
      "Du schaffst es nicht, Kunden schnell umfassend zu informieren und für Dein Produkt zu begeistern, sondern schreckst sie noch zusätzlich mit umständlichen PDFs und Formularen ab. Notwendige Informationen müssen wie Puzzlestücke aus der Website, PDFs und Emails zusammengesammelt werden.",
    illustration:
      // "/undraw_lost_re_xqjt.svg",
      "/undraw_throw_away_re_x60k.svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration4--dark.svg",
  },
  {
    title: "Von der Anfrage bis zum Angebot dauert es bei Dir länger als 3 Stunden",
    description:
      "Wenn Kunden lange (> 3 Stunden) auf Ihr Angebot warten müssen, dann ist das eine Aufforderung, sich bei Deinen zahlreichen Mitbewerbern umzuschauen. Wenn diese schneller ein Angebot machen, dann ist der Kunde dort einen Schritt weiter und wird sich nicht mehr für Dich interessieren. Der Umsatz ist damit verloren.",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  {
    title: "Deine Website hat keinen Wettbewerbsvorteil gegenüber dem Marktführer",
    description:
      "Interessenten wollen weder Geld noch Zeit verschwenden. Die meisten Hersteller schaffen es nicht, die hohe Qualität Ihrer Produkte zu transportieren und was sie vom Marktführer unterscheidet. Dadurch gehen Kunden lieber auf Nummer sicher und kaufen beim Branchenprimus.",
    illustration:
      "/undraw_feeling_blue_-4-b7q (1).svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration1--dark.svg",
  },
  // {
  //   label: "Client portal access",
  //   title: "Chaos in Vertrieb und Marketing durch die falschen Tools, undurchsichtige Excel-Sheets oder sogar Zettelwirtschaft",
  //   description:
  //     "Chaos sorgt dafür, dass Kundenanfragen nicht schnell oder nicht wirksam bearbeitet werden und der Umsatz an Deinen Konkurrenten geht, der es besser macht.",
  //   illustration:
  //     "/undraw_abstract_re_l9xy.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  // {
  //   label: "Client portal access",
  //   title: "Keine systematische Datenerhebung in Marketing und Vertrieb",
  //   description:
  //     "Dadurch bleiben Fehler in Vertrieb und Marketing unentdeckt und Du bleibst langfristig unter Deinen Umsatzmöglichkeiten.",
  //   illustration:
  //     "/undraw_logic_re_nyb4.svg",
  //   illustrationDark:
  //     "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  // },
  {
    label: "Client portal access",
    title: "Unzureichende Unterstützung durch digitale Systeme",
    description:
      "Mühsame, sich wiederholende Prozesse müssen ständig per Hand ausgeführt werden. Dadurch lähmst Du den Vertrieb und erzielst wenige Abschlüsse und damit wenig Umsatz.",
    illustration:
      "/undraw_autumn_re_rwy0.svg",
    illustrationDark:
      "https://assets.maccarianagency.com/svg/illustrations/illustration2--dark.svg",
  },
];

const Work = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={12}>
        {/* <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"secondary"}
          align={"center"}
        >
          Das machst Du als Hersteller falsch
        </Typography> */}
        <Typography variant={"h4"} sx={{ fontWeight: "bold", color: "#222", marginBottom: "80px" }} align={"center"}>
          Kennst Du das...?
        </Typography>
       
        <Grid container spacing={4}>
        {mock1.map((item, i) => (
          <Grid
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
            key={i}
            item
            container
            xs={12}
            spacing={4}
            mb={0}
            pt={1}
            sx={{paddingTop: "5px"}}
            // direction={i % 2 === 1 ? "row-reverse" : "row"}
          >
            <Grid item container alignItems={"center"} sx={{textAlign: "left"}} xs={12} sm={12} >
              <Box sx={{ width: "700px", mx: "auto"}}>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 700, fontSize: "16px", margin:0, padding:0 }}
                >
                  <Grid container sx={{margin:0 ,padding:0}}>
                    <Grid xs={1.5} sm={1} ><ClearOutlined sx={{color: "#66788", filter: "saturate(0.65)", fontSize: "28px", marginTop: "-2px"}}/></Grid>
                    <Grid xs={10.5} sm={11}><Typography sx={{fontWeight: "500", color: "#222"}}>{item.title}</Typography>
                    {/* <Typography color="text.secondary" sx={{fontSize: "13px"}}>
                  {item.description}
                </Typography> */}
                </Grid>
                  </Grid>
                  
                  
                </Typography>
              
                {/* <Button
                  size={"large"}
                  sx={{ marginTop: 2 }}
                  endIcon={
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={24}
                      height={24}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </Box>
                  }
                >
                  Learn more
                </Button> */}
              </Box>
            </Grid>
            {/* <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              xs={12}
              sm={6}
            >
              <Box
                component={"img"}
                src={`${
                  theme.palette.mode === "light"
                    ? item.illustration
                    : item.illustrationDark
                }`}
                sx={{filter: "grayscale(0.8)"}}
                alt={item.title}
                width={0.45}
                maxWidth={"30%"}
              />
            </Grid> */}
          </Grid>
        ))}
      </Grid>
      
        {/* <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
          sx={{my:4, maxWidth: "750px", mx: "auto"}}
        >
          Ohne ein bewährtes digitales System im Vertrieb schleichen sich immer wieder Fehler in Deine Prozesse ein, die langfristig hohe 7-stellige Umsatzeinbußen bedeuten können.
        </Typography> */}
        {/* <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          <Button
            color={"primary"}
            variant={"contained"}
            size={"large"}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width={20}
                height={20}
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            }
          >
            Contact us
          </Button>
        </Box> */}
      </Box>
      <center style={{marginBottom: "55px"}}>
          <div style={{width: "100%", height: "auto", margin: "80px 0 35px 0"}}>
        <Box component={"img"}  sx={{width:"min(700px,100%)"}} src={"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/chaotic_sales.png.png?alt=media&token=5bfbff66-8f85-4af7-a7fc-0fce5aeb9b15"}/>
        </div>
        </center>
        {/* <center style={{marginBottom: "55px"}}>
          <div style={{width: "100%", height: "auto", margin: "80px 0 35px 0"}}>
        <Box component={"img"}  sx={{width:"min(400px,100%)"}} src={"  https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/martin_profile_bw.png?alt=media&token=070e61a1-f6b4-43a0-a573-7df91622cc93"}/>
        </div>
        </center> */}
      <Typography variant={"h4"} sx={{ fontWeight: "bold", color: "#222", marginBottom: "80px" }} align={"center"}>
          Deswegen dauern Kaufentscheidungen bei Deinen Kunden so lange und der Umsatz kommt spät oder nie
        </Typography>
      <Grid container spacing={4}>
      {mock.map((item, i) => (
          <Grid
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
            key={i}
            item
            container
            xs={12}
            spacing={4}
            mb={0}
            // direction={i % 2 === 1 ? "row-reverse" : "row"}
          >
            <Grid item container alignItems={"center"} sx={{textAlign: "left"}} xs={12} sm={12} >
              <Box sx={{ width: "700px", mx: "auto"}}>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 700, fontSize: "16px" }}
                >
                  <Grid container>
                    <Grid xs={1.5} sm={1} ><ClearOutlined width={"18px"} sx={{ color: "#66788", fontSize: "28px", marginTop: "-2px"}}/></Grid>
                    <Grid xs={10.5} sm={11}><Typography sx={{fontWeight: "500", color: "#222"}}>{item.title}</Typography>
                    {/* <Typography color="text.secondary" sx={{fontSize: "13px"}}>
                  {item.description}
                </Typography> */}
                </Grid>
                  </Grid>
                  
                  
                </Typography>
              
                {/* <Button
                  size={"large"}
                  sx={{ marginTop: 2 }}
                  endIcon={
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={24}
                      height={24}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </Box>
                  }
                >
                  Learn more
                </Button> */}
              </Box>
            </Grid>
            {/* <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              xs={12}
              sm={6}
            >
              <Box
                component={"img"}
                src={`${
                  theme.palette.mode === "light"
                    ? item.illustration
                    : item.illustrationDark
                }`}
                sx={{filter: "grayscale(0.8)"}}
                alt={item.title}
                width={0.45}
                maxWidth={"30%"}
              />
            </Grid> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Work;
